import React, { useEffect, useState } from 'react'
import Nav from './nav'
import { auth, fireStore } from '../firebase'
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { deleteDoc, doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore'
import { func } from 'prop-types'
import { onAuthStateChanged } from 'firebase/auth'

export default function GarmentDetails() {
    let [pop,setPop] = useState(false)
    let [garmentNameEditor,setGarmentNameEditor] = useState(false)
    let [numberOfItemsEditor,setnumberOfItemsEditor] = useState(false)
    let [pricePerItemEditor,setpricePerItemEditor] = useState(false)
    let [garmentColorEditor,setGarmentColorEditor] = useState(false)
    let [supplierNameEditor,setsupplierNameEditor] = useState(false)
    let [supplierPhoneEditor,setsupplierPhoneEditor] = useState(false)
    const [garmentColor,setGarmentColor]= useState('')
        const [email,setemail]= useState()
        let yonas = 'yonas@gmail.com'
    const [garmentColorError,setGarmentColorError]= useState('')
    const [garmentName,setGarmentName]= useState('')
    const [garmentNameError,setGarmentNameError]= useState('')
    let [deleteModal,setdeleteModal] = useState(false)
    let [loading,setLoading] = useState(false)


    let [garment,setGarment] = useState([])
    let {garmentId} = useParams()
    let history = useHistory()

     useEffect(() => {
        // Fetch the courses data from the courses collection with the courseId
      
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            setemail(user.email)
          
      
           
          }
        });
      
        return () => {
          unsubscribe();
          setloading(false)
    
        };
      }, []);

    function garmentNameSet(e){
      setGarmentName(e)
      if(e.length ===0){
          setGarmentNameError('error')
      }
      else{
          setGarmentNameError('')

      }

  }
  function garmentColorSet(e){
    setGarmentColor(e)
    if(e.length ===0){
        setGarmentColorError('error')
    }
    else{
        setGarmentColorError('')

    }

}

    function popHandler(){
        if(pop === true){
            setPop(false)
        }
        else{
            setPop(true)
        }
    }
    const formatMoney = (amount) => {
      // Ensure the input is a number, and format it with commas
      return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits: 2,  // Limit to two decimal places
      }).format(amount);
    };
    
    function deleteModalHandler(){
        if(deleteModal === true){
            setdeleteModal(false)
        }
        else{
            setdeleteModal(true)
        }
    }

    async function deletegarment(){
        setLoading(true)
        let garmentDoc = doc(fireStore,'Garments',garmentId)
        const userDoc = doc(fireStore, 'Andnet', 'stats');
        const currentData = await getDoc(garmentDoc);
        const statsData = await getDoc(userDoc);
       
        const currentTotalExpense = statsData.data() && statsData.data().totalExpense || 0;
        const currentTotalMoneySpentOngarments = statsData.data() && statsData.data().totalMoneySpentOngarments || 0;
    
        const totalDeletedPrice = currentData.data().metricSystem === 'Meter'?parseFloat(currentData.data().garmentWidth) * parseFloat(currentData.data().numberOfItem) * parseFloat(currentData.data().pricePerItem):parseFloat(currentData.data().garmentWeight) * parseFloat(currentData.data().numberOfItem) * parseFloat(currentData.data().pricePerItem) ;
        const TotalExpense = isNaN(totalDeletedPrice) ? currentTotalExpense : currentTotalExpense - totalDeletedPrice;
        const TotalMoneySpentOngarments = isNaN(totalDeletedPrice) ? currentTotalMoneySpentOngarments : currentTotalMoneySpentOngarments - totalDeletedPrice;
     
      await setDoc(userDoc, { totalExpense: TotalExpense,totalMoneySpentOngarments:TotalMoneySpentOngarments }, { merge: true });

      await deleteDoc(garmentDoc).then(
         history.push('/admin/garment')
      )  
        
      

    }
    

    useEffect(() => {
        const garmentDoc = doc(fireStore, 'Garments', garmentId);
    
        // Subscribe to real-time updates
        const unsubscribe = onSnapshot(garmentDoc, (snapshot) => {
            if (snapshot.exists()) {
                setGarment([{ ...snapshot.data() }]); // Directly set the fetched data
            } else {
                console.error("No such document!");
            }
        });
    
        return () => unsubscribe();
    }, [fireStore, garmentId]); 

    function garmentNameEditorSet(){
      if(garmentNameEditor){
        setGarmentNameEditor(false)
      }
      else{
        setGarmentNameEditor(true)
        setGarmentName('')
      }
    }

    function handleSavepricePerItem(garmentId){
      if(pricePerItem.length !==0){
        let garmentDoc = doc(fireStore,'Garments',garmentId)
      setDoc(garmentDoc,{pricePerItem},{merge:true})
      setpricePerItemEditor(false)
      }
      else{
        setpricePerItemError('error')

      }
    }
    const convertTimestampToDate = (timestamp) => {
     if(timestamp){
        const date = timestamp.toDate();
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
     }
    };
    
    function pricePerItemEditorSet(){
      if(pricePerItemEditor){
        setpricePerItemEditor(false)
      }
      else{
        setpricePerItemEditor(true)
        setpricePerItem('')
      }
    }

    function handleSavegarmentName(garmentId){
      if(garmentName.length !==0){
        let garmentDoc = doc(fireStore,'Garments',garmentId)
      setDoc(garmentDoc,{garmentName},{merge:true})
      setGarmentNameEditor(false)
      }
      else{
        setGarmentNameError('error')

      }
    }
    function garmentNameEditorSet(){
      if(garmentNameEditor){
        setGarmentNameEditor(false)
      }
      else{
        setGarmentNameEditor(true)
        setGarmentName('')
      }
    }

    function handleSavegarmentName(garmentId){
      if(garmentName.length !==0){
        let garmentDoc = doc(fireStore,'Garments',garmentId)
      setDoc(garmentDoc,{garmentName},{merge:true})
      setGarmentNameEditor(false)
      }
      else{
        setGarmentNameError('error')

      }
    }

    function garmentColorEditorSet(){
      if(garmentColorEditor){
        setGarmentColorEditor(false)
      }
      else{
        setGarmentColorEditor(true)
        setGarmentColor('')
      }
    }

    function handleSavegarmentColor(garmentId){
      
      if(garmentColor.length !==0){
        let garmentDoc = doc(fireStore,'Garments',garmentId)
        setDoc(garmentDoc,{garmentColor},{merge:true})
        setGarmentColorEditor(false)
      }
      else{
        setGarmentColorEditor('error')

      }
    }
    

  return (
    <div>
   <Nav comp='adminApear' popProp={pop}/>
   <div className='h-full w-full bg-lightPrimary dark:!bg-navy-900 px-5'>

{
    garment.length !=0 ?
    garment.map(i=>{
        const {quantity,fabrics, supplierPhoneNumber,createdTime,garmentSize,fabricUsage,totalFabricRequired,totalCost,fabricName,note,reciept,garmentName,garmentPhoto,garmentWidth,garmentHeight,garmentWeight,stock,price,numberOfItem,garmentColor,garmentApplication,garmentIntendedFor} = i
        
        return(
<main className='mx-[12px] h-full flex-none transition-all md:pr-2   mt-5' onClick={popHandler}>
<nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl  p-2 backdrop-blur-xl ">
  <div className="ml-[6px]">
   
    <p className="shrink text-[33px] capitalize text-navy-700 ">
     
    </p>
  </div>
  <div className="flex items-center">
   
   
    <div className="relative flex">
      <div className="flex">
       
       
      </div>
      
    </div>
 
    
    <div className="relative flex ml-2 ">
      <div className="flex items-center ">

    
{/* <button className="page-book button button-main " style={{width:'auto',margin:'2px',zIndex:1001,backgroundColor:'white',color:'black',border:'1px solid black'}} >
           Add Accessory
                         </button>
                         <button className="page-book button button-main " style={{width:'auto',margin:'2px',zIndex:1001,backgroundColor:'white',color:'black',border:'1px solid black'}} >
           Add Finishing
                         </button>
                         <button className="page-book button button-main " style={{width:'auto',margin:'2px',zIndex:1001,backgroundColor:'white',color:'black',border:'1px solid black'}} >
           Add Packaging
                         </button> */}
                         <svg onClick={deleteModalHandler} xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-trash mr-2 cursor-pointer hover:text-red-600" viewBox="0 0 16 16">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
</svg>
      </div>
      <div className="py-2 top-8 -left-[180px] w-max absolute z-10 origin-top-right transition-all duration-300 ease-in-out scale-0">
        <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700  dark:shadow-none">
          <div className="p-4">
            <div className="flex items-center gap-2">
              <p className="text-sm font-bold text-navy-700 ">
                👋 Hey, Adela
              </p>{" "}
            </div>
          </div>
          <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />
          <div className="flex flex-col p-4">
            <Link
              to=''
              className="text-sm text-gray-800  hover:"
            >
              Profile Settings
            </Link>
                <Link
              to=''
              className="mt-3 text-sm text-gray-800  hover:"
            >
              Newsletter Settings
            </Link>
               <Link
              to=''
              className="mt-3 text-sm font-medium text-red-500 hover:text-red-500 transition duration-150 ease-out hover:ease-in"
            >
              Log Out
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

{
    deleteModal === true &&
    <div id="amountModal" class="">
    <div class="relative p-4 w-full h-full flex items-center justify-center deleteModal" >
        <div class="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5 w-full" >
        {
            loading === false ?
            <button onClick={deleteModalHandler} type="button" class="text-gray-900 absolute top-2 right-2 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="deleteModal">
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            <span class="sr-only">Close modal</span>
        </button>
        :
        <button  type="button" class="cursor-not-allowed text-gray-900 absolute top-2 right-2 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="deleteModal">
        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
        <span class="sr-only">Close modal</span>
    </button>
        }
            <p className='flex justify-center items-center mb-4'>
            <p class=" text-gray-900 css-7vq5hu mr-2" style={{fontSize:'20px'}}>
              
             Are you sure you want to delete {garmentName}? </p>
             <span className='items'></span>
            </p>

           
      
              {
                loading === false?
                <button onClick={deletegarment} className="page-book button button-main mt-6"style={{background:'red',color:'white'}} >   <span>Delete </span>
             </button>
             :
             <button  className="page-book button button-main mt-6 cursor-not-allowed"style={{background:'red',color:'white',cursor:'not-allowed'}} >   <span>Deleting </span>
             </button>
              }

{
                loading === false?
                <button onClick={deleteModalHandler} className="page-book button button-main mt-3 border-2 border" style={{background:'white',color:'black'}}>   <span>Cancel </span>
             </button>
             :
             <button  className="page-book button button-main mt-3 border-2 border cursor-not-allowed" style={{background:'white',color:'black',cursor:'not-allowed'}}>   <span>Cancel </span>
             </button>
              }
            
     
         
        </div>
    </div>
  </div>
}
<div class=" -800 py-8">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex flex-col md:flex-row -mx-4">
            {/* <div class="md:flex-1  px-4">
                <div class="h-[460px] rounded-lg bg-gray-300 -700 mb-4">
                    <img class="w-full h-full object-cover" src={garmentPhoto} alt="garment Image"/>
                </div>
             
            </div> */}
            <div class="md:flex-1 px-4">
            {
              garmentNameEditor === false ?
              <div className='flex items-center gap-2 mb-4'>
              <h2 class="text-2xl font-bold text-gray-800  ">{garmentName}</h2>
{
  email != yonas &&
  <svg onClick={garmentNameEditorSet} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil cursor-pointer" viewBox="0 0 16 16">
  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
</svg>
}
              </div>
              :
              <div className='flex items-center gap-2 mb-4'>
             <div style={{minWidth:'50%'}}>
             <input
            type="text"
            placeholder="garment Name"
            autoComplete="garment Name"
            defaultValue={garmentName}
            onChange={e=>garmentNameSet(e.target.value)}
            className="input book-input  "  style={{borderBottom:'1px solid black'}} 
          />
          {
            garmentNameError === 'error' &&
            <div  class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
  <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
  <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
          }
             </div>
             <button className="page-book button button-main" onClick={()=>handleSavegarmentName(garmentId)}>
            <span>Save</span>
          </button>
             <p className='cursor-pointer' onClick={garmentNameEditorSet}> X</p>
              </div>
            }

                
                <div class="flex mb-4">
                        <p class="font-bold text-gray-700 ">Production Cost: </p><span  class="text-gray-600 "> { formatMoney(totalCost)} ETB</span> 
                    <div>
                        <span class="font-bold text-gray-700 ml-3">Used Fabric : </span>
                        <span class="text-gray-600 "> {fabrics.map(i=>{
                          return(
                          <Link to={`/admin/fabric/${i.fabricId}`}>{(i.fabricName)}</Link>
                          )
                        })}</span>
                    </div>
                </div>
          
               <div class="mb-4">
                    <div class="flex items-center mt-2">
                    <span class="font-bold text-gray-700 "> Garments Produced: </span>

                        <span class=" text-gray-600 pl-2 "> {quantity} piece</span>
                    </div>
                </div>
                <div>
                    <span class="font-bold text-gray-700 ">Fabric Price Per Meter: </span>
                    <span class="text-gray-600  text-sm mt-2">
                    {fabrics.map(i=>{
                          return(formatMoney(i.pricePerMeter))
                        })} ETB
                    </span>
                </div>
                <div class="mb-4">
                    <div class="flex items-center mt-2">
                    <span class="font-bold text-gray-700 "> Garment Intended For: </span>

                        <span class=" text-gray-600 pl-2 "> {garmentIntendedFor}</span>
                    </div>
                </div>

                <div class="mb-4">
                    <div class="flex items-center mt-2">
                    <span class="font-bold text-gray-700 "> Garment Application </span>

                        <span class=" text-gray-600 pl-2 "> {garmentApplication}</span>
                    </div>
                </div>
                
                <div class="mb-4 flex items-center gap-2">
                    <div class="flex items-center mt-2">
                    <span class="font-bold text-gray-700 "> Time: </span>

                        <span class=" text-gray-600 pl-2 "> {convertTimestampToDate(createdTime)} </span>
                    </div>
                </div>
                <div class="mb-4">
                    <span class="font-bold text-gray-700 "> Production Type:</span>
                    <div class="flex items-center mt-2">
                    <span class=" text-gray-600 pl-2 "> {fabricUsage} </span>

                    </div>
                </div>
                <div class="mb-4">
                    <span class="font-bold text-gray-700 "> Fabric required for 1 Garment:</span>
                    <div class="flex items-center mt-2">
                        <div class="bg-black -700 text-white  py-2 px-4 rounded-full font-bold mr-2  ">{
                        fabrics.map(i=>{
                          return(i.fabricRequiredPerGarment)
                        })} Meter</div>
                    </div>
                </div>

                <div class="mb-4">
                    <span class="font-bold text-gray-700 "> Size:</span>
                    <div class="flex items-center mt-2">
                        <div class="bg-black -700 text-white  py-2 px-4 rounded-full font-bold mr-2  ">{garmentSize} ¬ {totalFabricRequired} Meter</div>
                    </div>
                </div>
               
           
            </div>
        </div>
    </div>
</div>

</main>
        )
    })
:
<div className='mx-[12px] h-full flex-none transition-all md:pr-2  '>
<div className="sc-dced6a82-0 fEUgVc">
  <div className="sc-ab4f884-0 gMSCii">
    <img src="https://cdn.arc.dev/images/developer-dashboard/jobs-empty-case.svg" />
    <div width="240px" className="sc-ab4f884-1 jVlTeU">
      <p className='items mt-6'>
        You haven’t added any any garments
        <br />
      </p>
    </div>
  </div>
</div>

</div>
}
    </div>

    </div>
  )
}
