import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import Home from './views/home'
import NotFound from './views/not-found'
import Admin from './views/admin'
import Garment from './views/garment'
import Accessories from './views/accessories'
import Accessory from './views/accessory'
import Fabric from './views/fabric'
import Fabrics from './views/fabrics'
import Login from './views/login'
import GarmentDetails from './views/garmentDetails'
import About from './views/about'
import ContactForm5 from './views/contact'
import Messages from './views/messages'
import Message from './views/message'
import Packaging from './views/packaging'
import FinishingProducts from './views/finishingProducts'
import FinishingProduct from './views/finishingProduct'
import Packagings from './views/packagings'


const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={Admin} exact path="/admin" />
        <Route component={Login} exact path="/login" />
        <Route component={About} exact path="/about" />
        <Route component={ContactForm5} exact path="/contact" />

        <Route component={Fabrics} exact path="/admin/fabric" />
        <Route component={Packagings} exact path="/admin/packaging" />
        <Route component={FinishingProducts} exact path="/admin/finishing" />
        <Route component={Garment} exact path="/admin/garment" />
        <Route component={Accessories} exact path="/admin/accessories" />
        <Route component={Accessory} exact path="/admin/accessory/:accessoryId" />
        <Route component={Packaging} exact path="/admin/packaging/:packagingId" />
        <Route component={FinishingProduct} exact path="/admin/finishing/:finishingProductId" />
        <Route component={Messages} exact path="/admin/messages" />
        <Route component={Fabric} exact path="/admin/fabric/:fabricId" />
        <Route component={GarmentDetails} exact path="/admin/garment/:garmentId" />
        <Route component={Message} exact path="/admin/message/:messageId" />





        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
