import React from 'react'
import { Link } from 'react-router-dom'
import './home.css'

export default function Footer() {
  return (
    <div className='w-full'>

        <footer class="bg-white rounded-lg shadow  m-4" style={{backgroundColor:'black'}}>
    <div class="w-full max-w-screen-xl mx-auto p-4 py-8">
        <div class="flex items-center justify-between mb-6">
           <Link  to='/' className='flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse home-logo1'>
            <img src="https://andnet.s3.us-east-1.amazonaws.com/kasterina__13_-removebg-preview+(3).png" alt="Logo" className='ml-5' style={{width:'100%'}} />
            </Link>

            <ul class="flex flex-wrap items-center  text-sm font-medium text-white sm:mb-0 ">
                <li>
                    <Link to="/about" class="hover:underline home-text153 capitalize  me-4 md:me-6">About us</Link>
                </li>
                <li>
                </li>
                <li>
                    <a href='/#collection' class="hover:underline home-text153 capitalize me-4 md:me-6">Collection</a>
                </li>
                <li>
                    <Link to="/contact" class="hover:underline home-text153 capitalize">Contact</Link>
                </li>
            </ul>
        </div>
        <div class="my-6  sm:mx-auto  lg:my-8" ></div>
        <div className='flex justify-between'>
        <span class="block text-sm text-white sm:text-center ">© 2025 <Link to='/' class="hover:underline home-text153 capitalize">Andnet™</Link>. All Rights Reserved.</span>

        <div class="flex mt-4 sm:justify-center sm:mt-0">
              <a href="#" target='_blank' class="text-white hover:text-gray-900 mx-2 ">
                  <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                        <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd"/>
                    </svg>
                  <span class="sr-only">Facebook page</span>
              </a>
              <a href="" target='_blank' class="text-white hover:text-gray-900 mx-2  ">
               
                    <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="w-4 h-4 bi bi-tiktok" viewBox="0 0 16 16">
  <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"/>
</svg>
                  <span class="sr-only">Tiktok</span>
              </a>
              <a href="" target='_blank' class="text-white hover:text-gray-900 mx-2  ">
              
                <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-instagram w-4 h-4" viewBox="0 0 16 16">
  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
</svg>
                  <span class="sr-only">Instagram</span>
              </a>
              <a href=""target='_blank' class="text-white hover:text-gray-900 mx-2  ">
                
                  <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="w-4 h-4 bi bi-telegram" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"/>
</svg>
                  <span class="sr-only">Telegram</span>
              </a>
         
          </div>
        </div>
    
    </div>
</footer>



            



    </div>
  )
}
