import { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, setDoc, serverTimestamp, onSnapshot } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import Nav from './nav';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';

const AddGarment = () => {
  const [garmentName, setGarmentName] = useState('');
  const [garmentWidth, setGarmentWidth] = useState();
  const [garmentDetailModal, setGarmentDetailModal] = useState(true);
  const [garmentDescriptionModal, setGarmentDescriptionModal] = useState(true);
  const [garment, setGarment] = useState([]);
  const [garmentHeight, setGarmentHeight] = useState();
      const [email,setemail]= useState()
      let yonas = 'yonas@gmail.com'
  const [addNewGarment, setaddNewGarment] = useState();
  const [garmentSize, setgarmentSize] = useState();
  const [fabricUsage, setFabricUsage] = useState();
  const [maxGarments, setmaxGarments] = useState();
  const [priceDetails, setpriceDetails] = useState();
  const [priceCalculation, setpriceCalculation] = useState();
  
  const [productionMessage, setProductionMessage] = useState('');
  const [customQuantity, setCustomQuantity] = useState("");
  const [garmentPrice, setGarmentPrice] = useState('');
  const [quantity, setQuantity] = useState(1); // Number of garments
  const [fabricIds, setFabricIds] = useState([]); // Array of selected fabric IDs
  const [selectedAccessories, setSelectedAccessories] = useState([]); // Array of selected accessory IDs
  const [accessoryQuantities, setAccessoryQuantities] = useState({}); // Store quantity for each accessory
  const [fabricQuantities, setFabricQuantities] = useState({}); // Store quantity for each fabric
  const [fabrics, setFabrics] = useState([]); // List of fabric data from Firestore
  const [accessories, setAccessories] = useState([]); // List of accessory data from Firestore
  const [garmentError, setGarmentError] = useState(null);
    const [AccessoryType,setAccessoryType]= useState('')
      const [AccessoryTypeError,setAccessoryTypeError]= useState('')
       const [garmentApplication,setgarmentApplication]= useState('')
          const [garmentApplicationError,setgarmentApplicationError]= useState('')
          const [garmentIntendedFor,setgarmentIntendedFor]= useState('')
          const [garmentIntendedForError,setgarmentIntendedForError]= useState('')
  let [pop, setPop] = useState(false);
  let [loading, setloading] = useState(false);
  let [success, setsuccess] = useState(false);
  const [totalFabric, setTotalFabric] = useState(0); // Initialize totalFabric to 0

  const db = getFirestore();
  const fabricRequirements = {
    Infants: {
      Jacket: { Standard: 0.6 / 1 },
      "Long sleeve t-shirt": { Standard: 0.7 / 4 },
      "Long sleeve shirt": { Standard: 0.7 / 4 },
      "Short sleeve t-shirt": { Standard: 0.7 / 6 },
      "Short sleeve shirt": { Standard: 0.7 / 6 },
      Tuta: { Standard: 0.7 / 5 },
      Complete: { Standard: 1.8 / 1 },
      "Diaper holder": { Standard: 0.5 / 3 }
    },
    Kids: {
      Complete: { Small: 1.8 / 1, Medium: 2 / 1 },
      "Diaper holder": { Small: 0.55 / 3 },
      Jacket: { Small: 1.05 / 3, Medium: 1.5 / 2, Large: 1 / 1, XL: 1.3 / 1, "2XL": 1.4 / 1 },
      "Long sleeve t-shirt": { Small: 0.8 / 3, Medium: 1 / 2, Large: 1.2 / 2, XL: 1.3 / 2 },
      "Long sleeve shirt": { Small: 0.8 / 3, Medium: 1 / 2, Large: 1.2 / 2, XL: 1.3 / 2 },
      Robe: { Small: 0.7 / 2, Medium: 0.8 / 1, Large: 0.9 / 1 },
      "Short sleeve t-shirt": { Small: 0.8 / 5, Medium: 1 / 4, Large: 1.2 / 4, XL: 1.3 / 3 },
      "Short sleeve shirt": { Small: 0.8 / 5, Medium: 1 / 4, Large: 1.2 / 4, XL: 1.3 / 3 },
      "Tight joggers": { Small: 0.5 / 2, Medium: 0.6 / 2, Large: 0.7 / 1 },
      Tuta: { Small: 0.45 / 3, Medium: 0.55 / 2, Large: 1.3 / 4, XL: 1.4 / 3, "2XL": 0.8 / 1 }
    },
    Adults: {
      Jacket: {
        Small: 1.2 / 2, Medium: 1.3 / 1, Large: 1.4 / 2,
        XL: 1.5 / 1, "2XL": 1.6 / 1
      },
      "Long sleeve t-shirt": {
        Small: 1.2 / 2, Medium: 1.2 / 2, Large: 1.3 / 2,
        XL: 1.5 / 1
      },
      "Long sleeve shirt": {
        Small: 1.2 / 2, Medium: 1.2 / 2, Large: 1.3 / 2,
        XL: 1.5 / 1
      },
      "Short sleeve t-shirt": {
        Small: 0.8 / 5, Medium: 1 / 4, Large: 1.2 / 4,
        XL: 1.3 / 3, "2XL": 1.6 / 2
      },
      "Short sleeve shirt": {
        Small: 0.8 / 5, Medium: 1 / 4, Large: 1.2 / 4,
        XL: 1.3 / 3, "2XL": 1.6 / 2
      },
      Tuta: {
        Small: 0.75 / 3, Medium: 0.85 / 2, Large: 1.8 / 3,
        XL: 1.9 / 2, "2XL": 1 / 1
      },
      Robe: {
        Large: 1 / 1, XL: 1.1 / 1, "2XL": 1.3 / 1
      }
    }
  };
  function handleGoToGarmentDescription(){
   if(!garmentName){
    setGarmentError(`Garment name can't be empty`)
   }
   else {
    setGarmentError('')
    if(!garmentIntendedFor){
      setGarmentError(`Garment Intended For can't be empty`)
    }
    else{
      setGarmentError('')
      if(!garmentApplication){
        setGarmentError(`Garment Application For can't be empty`)
      }
      else{
        setGarmentError('')
        // if(garmentPrice <= 0 || !garmentPrice){

        if(!garmentSize){
          setGarmentError(`Garment Size  can't be empty`)
        }
        else{
          
            setGarmentError('')
            setloading(true)
            setTimeout(() => {
              setGarmentDetailModal(false)
        
              setGarmentDescriptionModal(true)
              setloading(false)
            }, 1000);
        }
      }
    }
   }
  }
  function handleGoToGarmentDetail(){
    setloading(true)
    setTimeout(() => {
      setGarmentDetailModal(true)

      setGarmentDescriptionModal(false)
      setloading(false)
    }, 1000);
  }

  const formatNumber = (amount) => {
    // Ensure the input is a number, and format it with commas
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,  // Limit to two decimal places
    }).format(amount);
  };
  useEffect(() => {
    // Fetch the courses data from the courses collection with the courseId
  
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setemail(user.email)
      
  
       
      }
    });
  
    return () => {
      unsubscribe();
      setloading(false)

    };
  }, []);
  // Fetch fabrics and accessories from Firestore on component mount using onSnapshot for real-time updates
  useEffect(() => {
    const garmentUnsubscribe = onSnapshot(collection(db, "Garments"), (snapshot) => {
      const garmentList = snapshot.docs.map(doc => ({
        ...doc.data(),
        garmentId: doc.id,
      }));
      setGarment(garmentList);
    });
  
    const fabricUnsubscribe = onSnapshot(collection(db, "Fabrics"), (snapshot) => {
      setFabrics((prevFabrics) => {
        // Keep track of the currently selected fabric
        const previousSelectedFabric = prevFabrics.find(fabric => fabricIds.includes(fabric.id));
  
        const fabricList = snapshot.docs.map(doc => ({
          fabricName: doc.data().fabricName,
          fabricPhoto: doc.data().fabricPhoto,
          metricSystem: doc.data().metricSystem,
          fabricWidth: doc.data().fabricWidth,
          fabricHeight: doc.data().fabricHeight,
          numberOfItem: doc.data().numberOfItem,
          pricePerItem: doc.data().pricePerItem,
          pricePerMeter: doc.data().pricePerMeter,

          
          id: doc.id,
          stock: doc.data().stock || 0, 
        }));
  
        // If the previous selection is still available, keep it selected
        if (previousSelectedFabric && fabricList.some(fabric => fabric.id === previousSelectedFabric.id)) {
          setFabricIds([previousSelectedFabric.id]);
        }
  
        return fabricList;
      });
    });



  
    const accessoryUnsubscribe = onSnapshot(collection(db, "Accessories"), (snapshot) => {
      const accessoryList = snapshot.docs.map(doc => ({
        id: doc.id,
        accessoryName: doc.data().accessoryName,
        accessoryPhoto: doc.data().accessoryPhoto,
        stock: doc.data().stock || 0, 
      }));
      setAccessories(accessoryList);
    });
  
    return () => {
      fabricUnsubscribe();
      garmentUnsubscribe();
      accessoryUnsubscribe();
    };
  }, [db, fabricIds]);  
  
 
  
  

     function garmentApplicationSet(e){
          setgarmentApplication(e)
          if(e.length ===0){
              setgarmentApplicationError('error')
          }
          else{
              setgarmentApplicationError('')
  
          }
  
      }
      function garmentIntendedForSet(e){
        setgarmentIntendedFor(e)
        if(e.length ===0){
            setgarmentIntendedForError('error')
        }
        else{
            setgarmentIntendedForError('')
  
        }
  
    }
    function addNewGarmentModal(){
      setloading(true)
         
      setTimeout(() => {
        if(addNewGarment === true){
          setloading(false)
          setaddNewGarment(false)
          
        }
        else{
          setloading(false)
          setaddNewGarment(true)
        }
        
      }, 3000);
    }
  
  
  


  
  
  // useEffect to monitor changes in fabricQuantities and log them
  useEffect(() => {
    console.log(fabricQuantities); // This will log the updated fabric quantities whenever they change
  }, [fabricQuantities]);

  // Function to handle accessory selection and unselection
  const handleAccessorySelect = (id) => {
    if (selectedAccessories.includes(id)) {
      const newAccessories = selectedAccessories.filter(accessoryId => accessoryId !== id);
      setSelectedAccessories(newAccessories);

      const newQuantities = { ...accessoryQuantities };
      delete newQuantities[id];
      setAccessoryQuantities(newQuantities);
    } else {
      setSelectedAccessories(prevState => [...prevState, id]);
      setAccessoryQuantities(prevState => ({ ...prevState, [id]: 1 }));
    }
  };

  // Handle accessory quantity change
  const handleAccessoryQuantityChange = (id, quantity) => {
    setAccessoryQuantities(prevState => ({
      ...prevState,
      [id]: Math.max(1, quantity), // Prevent accessory quantity from being less than 1
    }));
  };
  const timestampToString = (timestamp) => {
    if(timestamp){
      const date = timestamp.toDate();
      const currentDate = new Date();
      const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);
    
      const secondsInMinute = 60;
      const secondsInHour = 3600;
      const secondsInDay = 86400;
      const secondsInWeek = 604800;
      const secondsInMonth = 2592000;
      const secondsInYear = 31536000;
    
      let relativeTime = '';
    
      if (timeDifferenceInSeconds < secondsInMinute) {
        relativeTime = `${timeDifferenceInSeconds}s ago`;
      } else if (timeDifferenceInSeconds < secondsInHour) {
        const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
        relativeTime = `${minutes}m ago`;
      } else if (timeDifferenceInSeconds < secondsInDay) {
        const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
        relativeTime = `${hours}h ago`;
      } else if (timeDifferenceInSeconds < secondsInWeek) {
        const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
        relativeTime = `${days}d ago`;
      } else if (timeDifferenceInSeconds < secondsInMonth) {
        const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
        relativeTime = `${weeks}w ago`;
      } else if (timeDifferenceInSeconds < secondsInYear) {
        const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
        relativeTime = `${months}mo ago`;
      } else {
        const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
        relativeTime = `${years}y ago`;
      }
    
      return relativeTime;
    }
    
    };
   
    
    
    
  function removeSuccess(){
    setsuccess(false)
  }
  
  function popHandler(){
    if(pop === true){
        setPop(false)
    }
    else{
        setPop(true)
    }
}
// Function to handle fabric selection
const handleFabricSelect = (fabricId, fabricWidth, fabricHeight, fabricUsage, stock, pricePerItem,numberOfItem,pricePerMeter) => {
  const isFabricSelected = fabricIds.includes(fabricId);
  if (isFabricSelected) {
    setFabricIds(fabricIds.filter(id => id !== fabricId));
    setProductionMessage("Fabric unselected.");
    return;
  }

  setFabricIds([fabricId]);

  const selectedFabric = fabrics.find(fabric => fabric.id === fabricId);
  if (!selectedFabric) return;
  setTimeout(() => {
    calculateGarments(selectedFabric, fabricWidth, fabricHeight, stock, pricePerItem,numberOfItem,selectedFabric.pricePerMeter);
  }, 0);
};

// Function to calculate how many garments can be made
const calculateGarments = (selectedFabric, fabricWidth, fabricHeight, stock, pricePerItem,numberOfItem,pricePerMeter) => {
  if (!selectedFabric) return;
  console.log(selectedFabric);

  

  if (!fabricRequirements[garmentIntendedFor] || !fabricRequirements[garmentIntendedFor][garmentApplication] || !fabricRequirements[garmentIntendedFor][garmentApplication][garmentSize]) {
    setProductionMessage("Invalid selection.");
    return;
  }

  const fabricRequiredPerGarment = fabricRequirements[garmentIntendedFor][garmentApplication][garmentSize];

  // Calculate available fabric area
  const fabricAvailable =  selectedFabric.fabricHeight * selectedFabric.numberOfItem;

  if (fabricUsage === "Full Stock") {
    const maxGarments = Math.floor(fabricAvailable / fabricRequiredPerGarment);
    setmaxGarments(maxGarments)
    const totalCost =  fabricRequiredPerGarment * maxGarments * selectedFabric.pricePerMeter;

    setProductionMessage(`You can make ${maxGarments} garment(s) from ${selectedFabric.fabricName}. 1 garment requires ${fabricRequiredPerGarment} Meter Fabric`);
    setpriceDetails(`Fabric Price Per meter = ${selectedFabric.pricePerMeter}ETB`)
  } else if (fabricUsage === "Custom") {
    const requiredFabric = fabricRequiredPerGarment * customQuantity;
    if (requiredFabric > fabricAvailable) {
    setpriceDetails(`Fabric Price Per meter = ${selectedFabric.pricePerMeter}ETB`)

      setProductionMessage(`Not enough fabric! You need ${requiredFabric.toFixed(2)}m² but only have ${fabricAvailable.toFixed(2)}m².`);
    } else {
      const totalCost =  fabricRequiredPerGarment * customQuantity * selectedFabric.pricePerMeter;
    setpriceDetails(`Fabric Price Per meter = ${selectedFabric.pricePerMeter}ETB`)
    setpriceCalculation(`Total Cost = ${selectedFabric.pricePerMeter}ETB * ${customQuantity} * ${fabricRequiredPerGarment}Meter = ${totalCost}ETB`)

      setProductionMessage(`Total Cost to make ${customQuantity} garment(s) is ${totalCost} ETB. 1 garment requires ${fabricRequiredPerGarment} Meter Fabric`);
    }
  }
};


const handleFabricQuantityChange = (fabricId, quantity) => {
  // Update the quantity of the selected fabric in the state
  setFabricQuantities(prevState => ({
    ...prevState,
    [fabricId]: quantity,  // Set the new quantity for this fabric
  }));

  // If only one fabric is selected, automatically set the quantity
  if (fabricIds.length === 1) {
    setFabricQuantities(prevState => ({
      ...prevState,
      [fabricId]: garmentWidth * garmentHeight, // Automatically set the required quantity
    }));
  }
};


const handleAddGarment = async () => {
  let errors = [];

  if (fabricIds.length === 0) errors.push("Fabric must be selected.");
  if (!garmentName) errors.push("Garment name is required.");
  // if (!garmentPrice) errors.push("Garment price is required.");
  if (!garmentApplication) errors.push("Garment application is required.");
  if (!garmentIntendedFor) errors.push("Garment intended for is required.");
  if (!garmentSize) errors.push("Garment size is required.");
  if (fabricUsage === 'Custom' && (!customQuantity || customQuantity) <= 0) errors.push("Valid garment quantity is required.");

  if (errors.length > 0) {
    setGarmentError(errors.join("\n"));
    return;
  }

  try {
    setloading(true);

    const garmentDocRef = doc(collection(db, "Garments"), uuidv4());

    // Find selected fabrics
    const selectedFabrics = fabricIds.map(fabricId => {
      return fabrics.find(f => f.id === fabricId);
    }).filter(f => f); // Filter out undefined values

    if (selectedFabrics.length === 0) {
      setGarmentError("Selected fabric(s) not found.");
      setloading(false);
      return;
    }

    // Calculate total fabric required
    const fabricRequiredPerGarment = fabricRequirements[garmentIntendedFor]?.[garmentApplication]?.[garmentSize] || 0;
    let quantityProduced = fabricUsage === 'Custom' ? customQuantity : maxGarments
    const totalFabricRequired = fabricRequiredPerGarment * quantityProduced;

    // Calculate total cost
    let totalCost = 0;
    let updatedFabrics = [];

    for (const fabric of selectedFabrics) {
      const fabricId = fabric.id;
      const fabricName = fabric.fabricName;
      const fabricWidth = fabric.fabricWidth;
      const fabricHeight = fabric.fabricHeight;
      const pricePerMeter = fabric.pricePerMeter;
      const stock = fabric.stock;
      let requiredFabricForThisGarment;

      (customQuantity?
       requiredFabricForThisGarment = fabricRequiredPerGarment * customQuantity
      :
       requiredFabricForThisGarment = fabricRequiredPerGarment * maxGarments)

      const updatedStock = stock - requiredFabricForThisGarment;
      
      // Update fabric stock in Firestore
      const fabricRef = doc(db, "Fabrics", fabricId);
      await setDoc(fabricRef, { stock: updatedStock }, { merge: true });

      // Add to total cost
      totalCost += requiredFabricForThisGarment * pricePerMeter;

      // Store fabric details in garment document
      updatedFabrics.push({
        fabricId,
        fabricName,
        fabricWidth,
        fabricHeight,
        pricePerMeter,
        fabricRequiredPerGarment,
        requiredFabricForGarment: requiredFabricForThisGarment,
      });
    }

    // Create garment data
    const garmentData = {
      garmentName,
      price: parseFloat(garmentPrice),
      createdTime: serverTimestamp(),
      garmentApplication,
      garmentIntendedFor,
      garmentSize,
      quantity:quantityProduced ,
      fabricUsage,
      totalFabricRequired,
      totalCost,
      fabrics: updatedFabrics, // Stores fabric details
    };

    // Store garment data in Firestore
    await setDoc(garmentDocRef, garmentData);

    setloading(false);
    setsuccess(true);

    // Reset form after successfully adding the garment
    setGarmentName('');
    setGarmentPrice('');
    setQuantity(1);
    setFabricIds([]);
    setGarmentError(null);
    setPop(true);
    setaddNewGarment(false)


  } catch (error) {
    setGarmentError("Error adding garment: " + error.message);
    setloading(false);
    setaddNewGarment(false)
  }
};





  return (
    <div>
      <Nav compType='4' />
     {
      fabrics.length !==0  ?
      <div className='h-full w-full bg-lightPrimary dark:!bg-navy-900 px-5'>

      <main className='mx-[12px] h-full flex-none transition-all md:pr-2   ' onClick={popHandler}>
             <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 ">
               <div className="ml-[6px]">
                
                 <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
                
                 </p>
               </div>
               <div className="flex items-center">
                
                
                 <div className="relative flex">
                   <div className="flex">
                     {
                       pop === true ?
                       <span className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden m">
                       <svg
                         stroke="currentColor"
                         fill="currentColor"
                         strokeWidth={0}
                         viewBox="0 0 20 20"
                         aria-hidden="true"
                         height="1em"
                         width="1em"
                         xmlns="http://www.w3.org/2000/svg"
                         onClick={popHandler}
                       >
                         <path
                           fillRule="evenodd"
                           d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                           clipRule="evenodd"
                         />
                       </svg>
                     </span>:
                      <span className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden m">
                      <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth={2}
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="h-5 w-5"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                       onClick={popHandler}
             
                      >
                        <line x1={21} y1={10} x2={3} y2={10} />
                        <line x1={21} y1={6} x2={3} y2={6} />
                        <line x1={21} y1={14} x2={3} y2={14} />
                        <line x1={21} y1={18} x2={3} y2={18} />
                        
                      </svg>
                    </span>
                     }
                 
                   </div>
                   
                 </div>
              
                 
                 <div className="relative flex ml-2 ">
                   <div className="flex">
                  
                   <Link
                     className="font-bold capitalize hover:text-navy-700 dark:hover:text-white css-7vq5hu"
                     to="/admin/garment"
                   >
                    Garment  
             
                   </Link>
                   </div>
                 
                 </div>
               </div>
             </nav>
      {
          loading === true ?
           <div className='relative flex justify-center items-center h-[90vh]'>
           <div className="typewriter">
             <div className="slide">
               <i />
             </div>
             <div className="paper" />
             <div className="keyboard" />
           </div>
           </div>
           :
           success == true ?
        <div id='successAddFabric' className='flex items-center justify-center'>
        
        
        <div class="relative p-4 w-full  h-full md:h-auto flex justify-center items-center"style={{width:"55%"}}>
         <div class="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5 " >
           
             <button onClick={removeSuccess}   type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
                 <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                 <span class="sr-only">Close modal</span>
             </button>
        
        
             <div class="w-12 h-12 rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
                 <svg aria-hidden="true" class="w-8 h-8 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                 <span class="sr-only">Success</span>
             </div>
             <p class="mb-4 text-lg font-semibold text-gray-900  capitalize">Successfully Added Garment</p>
        <button onClick={removeSuccess}  type="button" class="text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none dark:focus:ring-blue-800">Continue</button>
        
        
         </div>
        </div>
        
        
        
        </div>
           :
          !(garment.length === 0  || addNewGarment === true)  ?
      
      <div className="mt-4   mx-auto mb-auto h-full min-h-[84vh] p-2 tablePadding " style={{overflowX:'scroll'}}>

          {email != yonas && <button className="page-book button button-main absolute left-10 top-24" style={{width:'auto',zIndex:1001}} onClick={addNewGarmentModal}>
           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
             <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
           </svg>
                         </button>}
                         
           <div className="w">
           <table class="w-full text-sm text-leftt text-gray-500 overflow-scroll">
           <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
                       <tr>
                       <th scope="col" class="tablePadding text-center py-3">
                           </th>
                           <th scope="col" class="tablePadding text-center py-3">
                               Fabric Name
                           </th>
                           <th scope="col" class="tablePadding text-center py-3">
                               Quantity
                           </th>
                           <th scope="col" class="tablePadding text-center py-3">
                               Price
                           </th>
                           <th scope="col" class="tablePadding text-center py-3">
                               Time
                           </th>
                           <th scope="col" class="tablePadding text-center py-3">
                               Action
                           </th>
                       </tr>
                   </thead>
                {garment && garment.sort((a,b)=>b.createdTime-a.createdTime).map((i,index)=>{
                 const{createdTime,garmentName,fabrics,price,garmentWeight,quantity,garmentWidth,garmentHeight,garmentPhoto,garmentApplication,garmentId,numberOfItem,pricePerMeter, pricePerItem} = i
                 return(
                   <tbody>
                   <tr class="border-b">
                   <th scope="row" class="tablePadding  text-center  py-4 font-medium text-gray-900 whitespace-nowrap">
                           {index + 1}
                       </th>
                       <th scope="row" class="tablePadding  text-center  py-4 font-medium text-gray-900 whitespace-nowrap">
                           {garmentName}
                       </th>
                       <td class="tablePadding  text-center py-4">
                       {quantity} piece
                       </td>
                       <td class="tablePadding  text-center py-4">
                       {fabrics.map(i=>{
                          return(formatNumber(i.pricePerMeter * quantity * i.fabricRequiredPerGarment))
                        })} ETB
                       </td>
                       <td class="tablePadding  text-center py-4">
                     {timestampToString(createdTime)}
                       </td>
                       <td class="tablePadding  text-center py-4">
                           <Link to={`/admin/garment/${garmentId}`}>
                                           <a  class="font-medium text-blue-600  hover:underline">{email != 'yonas@gmail.com'?'Edit':'View'}</a></Link>
                           
                       </td>
                   </tr>
                   </tbody>
           
           
           
           
           
                 )
                })}
           </table>
           
           </div>
           
                </div>
                :
                garmentDetailModal === true && email != yonas ?
              <div className="fabricDetails page-inputs pt-5s mx-auto mb-auto h-full  p-2 md:pr-2">
                {garmentError && <div style={{ color: 'red' }}>{garmentError}</div>}
                <p className="header" style={{fontSize:'25px'}}>

Garment Details 
</p> 
<div class="relative z-0 w-full  group">

<input
  type="text"
  name='loating_name'
  onChange={e=>setGarmentName(e.target.value)}
  placeholder=''
  style={{borderBottom:'1px solid black',borderRadius:'0'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
/>
<label for="floating_name" class="peer-focus:font-medium absolute text-sm  text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Garment Name </label>
</div> 


              
              
        
<select name="Intended Garment Application" id=""   style={{borderBottom:'1px solid black',borderRadius:'0'}} className={`${garmentIntendedFor ? 'text-gray-900': 'text-gray-500'} block py-2.5 px-0 w-full text-sm  bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer`}
                  onChange={e=>garmentIntendedForSet(e.target.value)}
                  >
                <option value="" className='input book-input ' >Intended Garment Group</option>
                <option value="Infants" className='input book-input '>Infants</option>
                  <option value="Kids" className='input book-input '>Kids</option>
                  <option value="Adults" className='input book-input '>Adults</option>
                 
      
      
                </select> 
               {
                  garmentIntendedForError === 'error' &&
                  <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
        <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
        <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>
      
      </div>
                } 
             {
              garmentIntendedFor === 'Infants'?
              <div className='w-full'>
                    <select name="Intended Garment Application" id="" style={{borderBottom:'1px solid black',borderRadius:'0'}} className={`${garmentApplication ? 'text-gray-900': 'text-gray-500'} block py-2.5 px-0 w-full text-sm  bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer`}
                  onChange={e=>garmentApplicationSet(e.target.value)}
                  >
                <option value="" className='input book-input ' >Intended Garment Application (Infants)</option>
      
                <option value="Complete" className='input book-input '>Complete</option>
                <option value="Diaper holder" className='input book-input '>Diaper holder</option>
                <option value="Jacket" className='input book-input '>Jackets</option>
                <option value="Long sleeve shirt" className='input book-input '>Long sleeve shirt</option>
                <option value="Long sleeve t-shirt" className='input book-input '>Long sleeve t-shirt</option>
                <option value="Short sleeve shirt" className='input book-input '>Short sleeve shirt</option>
                <option value="Short sleeve t-shirt" className='input book-input '>Short sleeve t-shirt</option>
                  <option value="Tuta" className='input book-input '>Tuta</option>
      
      
      
                </select>
                {
                  garmentApplicationError === 'error' &&
                  <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
        <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
        <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>
      
      </div>
                }
              </div>
              :
              garmentIntendedFor === 'Kids'?
              <div className='w-full'>
                    <select name="Intended Garment Application" id="" style={{borderBottom:'1px solid black',borderRadius:'0'}} className={`${garmentApplication ? 'text-gray-900': 'text-gray-500'} block py-2.5 px-0 w-full text-sm  bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer`}
                  onChange={e=>garmentApplicationSet(e.target.value)}
                  >
                <option value="" className='input book-input ' >Intended Garment Application (Kids)</option>
                <option value="Complete" className='input book-input '>Complete</option>
                <option value="Diaper holder" className='input book-input '>Diaper holder</option>
                <option value="Jacket" className='input book-input '>Jackets</option>
                <option value="Long sleeve shirt" className='input book-input '>Long sleeve shirt</option>
                <option value="Long sleeve t-shirt" className='input book-input '>Long sleeve t-shirt</option>
                <option value="Robe" className='input book-input '>Robe</option>
                <option value="Short sleeve shirt" className='input book-input '>Short sleeve shirt</option>
                <option value="Short sleeve t-shirt" className='input book-input '>Short sleeve t-shirt</option>
                  <option value="Tight joggers" className='input book-input '>Tight joggers</option>
                  <option value="Tuta" className='input book-input '>Tuta</option>
      
      
      
                </select>
                {
                  garmentApplicationError === 'error' &&
                  <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
        <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
        <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>
      
      </div>
                }
              </div>
              :
              garmentIntendedFor === 'Adults'&&
              <div className='w-full'>
                    <select name="Intended Garment Application" id="" style={{borderBottom:'1px solid black',borderRadius:'0'}} className={`${garmentApplication ? 'text-gray-900': 'text-gray-500'} block py-2.5 px-0 w-full text-sm  bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer`}
                  onChange={e=>garmentApplicationSet(e.target.value)}
                  >
                <option value="" className='input book-input ' >Intended Garment Application (Adults)</option>
                <option value="Jacket" className='input book-input '>Jackets</option>
                <option value="Long sleeve shirt" className='input book-input '>Long sleeve shirt</option>
                <option value="Long sleeve t-shirt" className='input book-input '>Long sleeve t-shirt</option>
                <option value="Robe" className='input book-input '>Robe</option>
                <option value="Short sleeve shirt" className='input book-input '>Short sleeve shirt</option>
                <option value="Short sleeve t-shirt" className='input book-input '>Short sleeve t-shirt</option>
                  <option value="Tuta" className='input book-input '>Tuta</option>
      
                  
      
                </select>
                {
                  garmentApplicationError === 'error' &&
                  <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
        <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
        <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>
      
      </div>
                }
      
        </div>
              
            }

            {
              garmentIntendedFor  && 

<div class="relative z-0 w-full  group">

{
  garmentIntendedFor === 'Kids'?
  <select name="Intended Garment Application" id="" style={{borderBottom:'1px solid black',borderRadius:'0'}} className={`${garmentApplication ? 'text-gray-900': 'text-gray-500'} block py-2.5 px-0 w-full text-sm  bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer`}
            onChange={e=>setgarmentSize(e.target.value)}
            >
          <option value="" className='input book-input ' >Garment Size</option>
      
            {
              (garmentIntendedFor === 'Adults' && garmentApplication === 'Robe') ?
              <option value="Large" className='input book-input '>Large</option>

              :
              <option value="Small" className='input book-input '>Small</option>
            }

{
              (garmentIntendedFor === 'Adults' && garmentApplication === 'Robe') ?
              <option value="XL" className='input book-input '>XL</option>

              :
              <option value="Medium" className='input book-input '>Medium</option>
            }

{
              (garmentIntendedFor === 'Adults' && garmentApplication === 'Robe') &&
              <option value="2XL" className='input book-input '>2XL</option>

             
            }
            {
              garmentIntendedFor === 'Kids' &&  !(garmentApplication === 'Complete'|| garmentApplication === 'Diaper holder')  &&
              <option value="Large" className='input book-input '>Large</option>
              
            }
            {
              garmentIntendedFor === 'Kids' &&  !(garmentApplication === 'Complete'|| garmentApplication === 'Tight joggers'|| garmentApplication === 'Robe' || garmentApplication === 'Diaper holder')  &&
              <option value="XL" className='input book-input '>XL</option>
              
            }
            
            {
              garmentIntendedFor === 'Kids' ? (garmentApplication === 'Tuta' || garmentApplication === 'Jacket')&&
              
              <option value="2XL" className='input book-input '>2XL</option>
              :
                garmentIntendedFor === 'Kids' &&  !(garmentApplication === 'Complete'|| garmentApplication === 'Robe'|| garmentApplication === 'Tight joggers'||garmentApplication === 'Short sleeve t-shirt' || garmentApplication === 'Short sleeve shirt')  &&
                <option value="2XL" className='input book-input '>2XL</option>
                
              
            }
          
      
      
      
          </select>
          :
          garmentIntendedFor === 'Adults'?
          <select name="Intended Garment Application" id="" style={{borderBottom:'1px solid black',borderRadius:'0'}} className={`${garmentApplication ? 'text-gray-900': 'text-gray-500'} block py-2.5 px-0 w-full text-sm  bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer`}
            onChange={e=>setgarmentSize(e.target.value)}
            >
          <option value="" className='input book-input ' >Garment Size</option>
      
            {
              (garmentIntendedFor === 'Adults' && garmentApplication === 'Robe') ?
              <option value="Large" className='input book-input '>Large</option>

              :
              <option value="Small" className='input book-input '>Small</option>
            }

{
              (garmentIntendedFor === 'Adults' && garmentApplication === 'Robe') ?
              <option value="XL" className='input book-input '>XL</option>

              :
              <option value="Medium" className='input book-input '>Medium</option>
            }

{
              (garmentIntendedFor === 'Adults' && garmentApplication === 'Robe') &&
              <option value="2XL" className='input book-input '>2XL</option>

             
            }
            {
              garmentIntendedFor === 'Adults' &&  !(garmentApplication === 'Complete'|| garmentApplication === 'Robe'|| garmentApplication === 'Diaper holder')  &&
              <option value="Large" className='input book-input '>Large</option>
              
            }
            {
              garmentIntendedFor === 'Adults' &&  !(garmentApplication === 'Complete'|| garmentApplication === 'Tight joggers'|| garmentApplication === 'Robe' || garmentApplication === 'Diaper holder')  &&
              <option value="XL" className='input book-input '>XL</option>
              
            }
            
            {
              garmentIntendedFor === 'Adults' ? (garmentApplication === 'Tuta' || garmentApplication === 'Jacket'||garmentApplication === 'Short sleeve t-shirt' || garmentApplication === 'Short sleeve shirt')&&
              <option value="2XL" className='input book-input '>2XL</option>
              :
                garmentIntendedFor === 'Adults' &&  !(garmentApplication === 'Complete'|| garmentApplication === 'Robe'|| garmentApplication === 'Tight joggers')  &&
                <option value="2XL" className='input book-input '>2XL</option>
                
              
            }
          
      
      
      
          </select>
          :
          garmentIntendedFor === 'Infants'&&
          <select name="Intended Garment Application" id="" style={{borderBottom:'1px solid black',borderRadius:'0'}} className={`${garmentApplication ? 'text-gray-900': 'text-gray-500'} block py-2.5 px-0 w-full text-sm  bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer`}
            onChange={e=>setgarmentSize(e.target.value)}
            >
          <option value="" className='input book-input ' >Garment Size</option>
          <option value="Standard" className='input book-input ' >Standard</option>
      
          
      
      
      
          </select>
}

</div> 

}



   <div className="page-button mb-10">
   <button className="page-book button button-main" onClick={handleGoToGarmentDescription}>
     <span>Next</span>
   </button>
</div>
   
 </div>
                :
                email != yonas ?
              <div className="fabricDetails page-inputs pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
                {
        <button className="page-book button button-main absolute left-10 top-24" style={{backgroundColor:'red',width:'auto',zIndex:1000}} onClick={handleGoToGarmentDetail}>
       Back
        </button>
      }
                {garmentError && <div style={{ color: 'red' }}>{garmentError}</div>}
          
                <p className="header" style={{fontSize:'25px'}}>

{garmentIntendedFor + '~ ' +garmentApplication + ' ~ '+garmentSize}
</p> 
{/* <div class="relative z-0 w-full  group">

<input
  type="number"
  name='loating_name'
  onChange={e=>setQuantity(e.target.value)}
  placeholder=''
  style={{borderBottom:'1px solid black',borderRadius:'0'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
/>
<label for="floating_name" class="peer-focus:font-medium absolute text-sm  text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Garment Quantity </label>
</div> */}
 {/* <div class="relative z-0 w-full  group">

<input
  type="number"
  name='loating_name'
  onChange={e=>setGarmentWidth(e.target.value)}
  placeholder=''
  style={{borderBottom:'1px solid black',borderRadius:'0'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
/>
<label for="floating_name" class="peer-focus:font-medium absolute text-sm  text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Garment Width </label>
</div>   */}

{/* <div class="relative z-0 w-full  group">

<input
  type="number"
  name='loating_name'
  onChange={e=>setGarmentHeight(e.target.value)}
  placeholder=''
  style={{borderBottom:'1px solid black',borderRadius:'0'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
/>
<label for="floating_name" class="peer-focus:font-medium absolute text-sm  text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Garment Height </label>
</div>  */}
              
      
           
      
                {/* Fabric Section */}
              {/* Fabric Usage Dropdown */}
      <select
        onChange={(e) => setFabricUsage(e.target.value)}
        style={{ borderBottom: '1px solid black', borderRadius: '0' }}
        className="block py-2.5 px-0 w-full text-sm bg-transparent border-gray-300"
      >
        <option value="">Fabric Usage</option>
        <option value="Full Stock">Full Stock</option>
        <option value="Custom">Custom</option>
      </select>
      <div className='border p-6 text-green-900'>
      <div className='result-text'>{priceDetails}</div>
      <div className='result-text'>{priceCalculation}</div>
      </div>

      {fabricUsage && (
        <div>
          <label>Select Fabric:</label>
          <div>
            {fabrics.length > 0 ? (
              fabrics.map((fabric) => (
                <div key={fabric.id} style={{ marginBottom: '20px' }}>
                  <button
                    onClick={() => handleFabricSelect(fabric.id, fabric.fabricWidth, fabric.fabricHeight, fabricUsage, fabric.stock, fabric.pricePerItem,fabric.numberOfItem,fabric.pricePerMeter)}
                    style={{
                      margin: '5px',
                      backgroundColor: fabricIds.includes(fabric.id) ? 'green' : 'black',
                      textAlign: 'center',
                    }}
                    className='button button-main'
                  >
                    <div className="flex">
                      <span>{fabric.fabricName}</span>
                    </div>
                  </button>
                </div>
              ))
            ) : (
              <p>No fabrics available <a className='underline' href='/admin/fabric'>Add Fabric</a></p>
            )}
          </div>
        

          {/* Custom Quantity Input */}
          {fabricUsage === "Custom" && (
            <div style={{ marginTop: "10px" }} className='flex items-center'>
             
             
              <div class="relative z-0 w-full  group">

<input
  type="number"
  min='1'
  value={customQuantity}

  name='loating_name'
  onChange={(e) => setCustomQuantity(Number(e.target.value))}
  placeholder=''
  style={{borderBottom:'1px solid black',borderRadius:'0'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
/>
<label for="floating_name" class="peer-focus:font-medium absolute text-sm  text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Number of Garments: </label>
</div> 

              <button style={{border:'1px solid black'}} className="ml-6 button-secondary  button-secondary-mobile button bg-transparent"onClick={() => calculateGarments(fabrics.find(fabric => fabricIds.includes(fabric.id)), 0, 0, 0, 0)}>
                Check 
              </button>
            </div>
          )}

          {/* Result Message */}
          <p className='text-green-900 mt-5'>{productionMessage}</p>
        </div>
      )}
            {productionMessage.includes('1 garment requires')&&    <div className="page-lower">
              
              <div className="page-button mb-10">
                <button className="page-book button button-main" onClick={handleAddGarment}>
                  <span>Add Garment</span>
                </button>
                
              </div>
            </div>}
              </div>
                  :
<div className='mx-[12px] h-full flex-none transition-all md:pr-2  '>
<div className="sc-dced6a82-0 fEUgVc">
  <div className="sc-ab4f884-0 gMSCii">
    <img src="https://cdn.arc.dev/images/developer-dashboard/jobs-empty-case.svg" />
    <div width="240px" className="sc-ab4f884-1 jVlTeU">
      <p className='items mt-6'>
        You haven’t added any any Garment
        <br />
      </p>
    </div>
  </div>
</div>

</div>

      }
      </main>
            </div>
            :
            <div className='mx-[12px] h-full flex-none transition-all md:pr-2 '>
            <div className="sc-dced6a82-0 fEUgVc">
              <div className="sc-ab4f884-0 gMSCii">
                <img src="https://cdn.arc.dev/images/developer-dashboard/jobs-empty-case.svg" />
                <div width="240px" className="sc-ab4f884-1 jVlTeU">
                  <p className='items mt-6'>
                    You haven’t added any any Fabrics
                    <br />
                    <div className="flex justify-center my-5">
        
         <Link to='/admin/fabric'>
         <button className="page-book button button-main" o>
            <span>Add Fabric</span>
          </button></Link>
          
      </div>
                  </p>
                </div>
              </div>
            </div>
            
            </div>
     }
    </div>
  );
};

export default AddGarment;
