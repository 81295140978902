import React, { useEffect, useState } from 'react'
import Nav from './nav'
import { fireStore } from '../firebase'
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { deleteDoc, doc, getDoc, onSnapshot, setDoc,collection } from 'firebase/firestore'
import { func } from 'prop-types'

export default function Messages() {
    let [pop,setPop] = useState(false)
    let [loading,setloading] = useState(false)
    let [messages,setMessages] = useState([])
    let [Error,setError] = useState('')

   
const timestampToString = (timestamp) => {
  if(timestamp){
    const date = timestamp.toDate();
    const currentDate = new Date();
    const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);
  
    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInWeek = 604800;
    const secondsInMonth = 2592000;
    const secondsInYear = 31536000;
  
    let relativeTime = '';
  
    if (timeDifferenceInSeconds < secondsInMinute) {
      relativeTime = `${timeDifferenceInSeconds}s ago`;
    } else if (timeDifferenceInSeconds < secondsInHour) {
      const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
      relativeTime = `${minutes}m ago`;
    } else if (timeDifferenceInSeconds < secondsInDay) {
      const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
      relativeTime = `${hours}h ago`;
    } else if (timeDifferenceInSeconds < secondsInWeek) {
      const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
      relativeTime = `${days}d ago`;
    } else if (timeDifferenceInSeconds < secondsInMonth) {
      const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
      relativeTime = `${weeks}w ago`;
    } else if (timeDifferenceInSeconds < secondsInYear) {
      const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
      relativeTime = `${months}mo ago`;
    } else {
      const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
      relativeTime = `${years}y ago`;
    }
  
    return relativeTime;
  };
}
   
    useEffect(() => {
      const unsubscribe = onSnapshot(
          collection(fireStore, 'Messages'),
          (docs) => {
              let temp = [];
              docs.forEach(snapshot => {
                  // Push data into the temporary array with AccessoryId
                  temp.push({ ...snapshot.data(), messageId: snapshot.id });
              });
              setMessages(temp); // Update the state with the fetched data
              setloading(false);  // Set loading to false when data is fetched
          },
          (err) => {
              setError(err.message);  // Handle any error while fetching
              setloading(false);
          }
      );
    
      // Cleanup on unmount
      return () => unsubscribe();
    }, []);

  return (
    <div>
   <Nav  compType='5' popProp={pop}/>
   {true && true ?
  <div className='mx-[12px] h-full flex-none transition-all md:pr-2  '>
  <div className="sc-dced6a82-0 fEUgVc">
    <div className="sc-ab4f884-0 gMSCii">
      <img src="https://cdn.arc.dev/images/developer-dashboard/jobs-empty-case.svg" />
      <div width="240px" className="sc-ab4f884-1 jVlTeU">
        <p className='items mt-6'>
          This feature is not available
          <br />
        </p>
      </div>
    </div>
  </div>
  
  </div>
  :
   <div className='h-full w-full bg-lightPrimary dark:!bg-navy-900 px-5'>
    {
      messages.length !=0 ?
      <table class="w-full text-sm text-left rtl:text-right text-gray-500 mt-10">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
            <tr>
            <th scope="col" class="px-6 text-center py-3">
                </th>
                <th scope="col" class="px-6 text-center py-3">
                    Sender's Name
                </th>
                <th scope="col" class="px-6 text-center py-3">
                    Email
                </th>
               
                <th scope="col" class="px-6 text-center py-3">
                    Time
                </th>
                <th scope="col" class="px-6 text-center py-3">
                    Action
                </th>
            </tr>
        </thead>
     {messages && messages.map((i,index)=>{
      const{createdTime,fullName,email,messageId} = i
      return(
        <tbody>
        <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
        <th scope="row" class="px-6 text-center py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {index + 1}
            </th>
            <th scope="row" class="px-6 text-center py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {fullName}
            </th>
            <td class="px-6 text-center py-4">
            {email}
            </td>
           
            <td class="px-6 text-center py-4">
          {timestampToString(createdTime)}
            </td>
            <td class="px-6 text-center py-4">
                <Link to={`/admin/message/${messageId}`}>
                <a  class="font-medium text-blue-600  hover:underline">View</a></Link>
            </td>
        </tr>
        </tbody>





      )
     })}
</table>
:
<div className='mx-[12px] h-full flex-none transition-all md:pr-2  '>
<div className="sc-dced6a82-0 fEUgVc">
  <div className="sc-ab4f884-0 gMSCii">
    <img src="https://cdn.arc.dev/images/developer-dashboard/jobs-empty-case.svg" />
    <div width="240px" className="sc-ab4f884-1 jVlTeU">
      <p className='items mt-6'>
        You haven’t recieved any messages
        <br />
      </p>
    </div>
  </div>
</div>

</div>
    }

    </div>
}
    </div>
  )
}
