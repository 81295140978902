// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyALgnPc-MFO_f4kXR_8qEPee7hTbCVi9Mw",
  authDomain: "andnet-68afb.firebaseapp.com",
  projectId: "andnet-68afb",
  storageBucket: "andnet-68afb.firebasestorage.app",
  messagingSenderId: "87911546947",
  appId: "1:87911546947:web:76d54d566528333cec54f6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export let fireStore = getFirestore()
export let auth  = getAuth()
export let storage  = getStorage()

