import React, { useEffect, useState } from 'react'
import Nav from './nav'
import { fireStore } from '../firebase'
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { deleteDoc, doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore'
import { func } from 'prop-types'

export default function MessageDetails() {
    let [pop,setPop] = useState(false)
   
    let [deleteModal,setdeleteModal] = useState(false)
    let [loading,setLoading] = useState(false)


    let [message,setmessage] = useState([])
    let {messageId} = useParams()
    let history = useHistory()



    function popHandler(){
        if(pop === true){
            setPop(false)
        }
        else{
            setPop(true)
        }
    }
 
    
    function deleteModalHandler(){
        if(deleteModal === true){
            setdeleteModal(false)
        }
        else{
            setdeleteModal(true)
        }
    }

    async function deletemessage(){
        setLoading(true)
        let messageDoc = doc(fireStore,'Messages',messageId)
       
        

      await deleteDoc(messageDoc).then(
         history.push('/admin/messages')
      )  
        
      

    }
    

    useEffect(() => {
        const messageDoc = doc(fireStore, 'Messages', messageId);
    
        // Subscribe to real-time updates
        const unsubscribe = onSnapshot(messageDoc, (snapshot) => {
            if (snapshot.exists()) {
                setmessage([{ ...snapshot.data() }]); // Directly set the fetched data
            } else {
                console.error("No such document!");
            }
        });
    
        return () => unsubscribe();
    }, [fireStore, messageId]); 

  
    const convertTimestampToDate = (timestamp) => {
     if(timestamp){
        const date = timestamp.toDate();
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
     }
    };
 


    


    
    

  return (
    <div>
   <Nav comp='adminApear' popProp={pop}/>
 <div>
{
  true && true ?
  <div className='mx-[12px] h-full flex-none transition-all md:pr-2  '>
  <div className="sc-dced6a82-0 fEUgVc">
    <div className="sc-ab4f884-0 gMSCii">
      <img src="https://cdn.arc.dev/images/developer-dashboard/jobs-empty-case.svg" />
      <div width="240px" className="sc-ab4f884-1 jVlTeU">
        <p className='items mt-6'>
          This feature is not available
          <br />
        </p>
      </div>
    </div>
  </div>
  
  </div>
  :
  <div className='h-full w-full bg-lightPrimary dark:!bg-navy-900 px-5'>

  {
      message.length !=0 ?
      message.map(i=>{
          const {fullName,messageId,email,message,createdTime} = i
          return(
  <main className='mx-[12px] h-full flex-none transition-all md:pr-2   ' onClick={popHandler}>
  <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
    <div className="ml-[6px]">
     
      <p className="shrink text-[33px] capitalize text-navy-700 ">
        <Link
          className="font-bold capitalize hover:text-navy-700 dark:hover:text-white css-7vq5hu"
          to={`/admin/message/${messageId}`}
        >
        Message
        
        </Link>
      </p>
    </div>
    <div className="flex items-center">
     
     
      <div className="relative flex">
        <div className="flex">
          {
            pop === true ?
            <span className="flex cursor-pointer text-xl text-gray-600  xl:hidden m">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth={0}
              viewBox="0 0 20 20"
              aria-hidden="true"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
              onClick={popHandler}
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>:
           <span className="flex cursor-pointer text-xl text-gray-600  xl:hidden m">
           <svg
             stroke="currentColor"
             fill="none"
             strokeWidth={2}
             viewBox="0 0 24 24"
             strokeLinecap="round"
             strokeLinejoin="round"
             className="h-5 w-5"
             height="1em"
             width="1em"
             xmlns="http://www.w3.org/2000/svg"
            onClick={popHandler}
  
           >
             <line x1={21} y1={10} x2={3} y2={10} />
             <line x1={21} y1={6} x2={3} y2={6} />
             <line x1={21} y1={14} x2={3} y2={14} />
             <line x1={21} y1={18} x2={3} y2={18} />
             
           </svg>
         </span>
          }
         
        </div>
        
      </div>
   
      
      <div className="relative flex ml-2 ">
        <div className="flex items-center ">
        <svg onClick={deleteModalHandler} xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-trash mr-2 cursor-pointer hover:text-red-600" viewBox="0 0 16 16">
    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
  </svg>
      
        </div>
        <div className="py-2 top-8 -left-[180px] w-max absolute z-10 origin-top-right transition-all duration-300 ease-in-out scale-0">
          <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700  dark:shadow-none">
            <div className="p-4">
              <div className="flex items-center gap-2">
                <p className="text-sm font-bold text-navy-700 ">
                  👋 Hey, Adela
                </p>{" "}
              </div>
            </div>
            <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />
            <div className="flex flex-col p-4">
              <Link
                to=''
                className="text-sm text-gray-800  hover:"
              >
                Profile Settings
              </Link>
                  <Link
                to=''
                className="mt-3 text-sm text-gray-800  hover:"
              >
                Newsletter Settings
              </Link>
                 <Link
                to=''
                className="mt-3 text-sm font-medium text-red-500 hover:text-red-500 transition duration-150 ease-out hover:ease-in"
              >
                Log Out
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
  
  {
      deleteModal === true &&
      <div id="amountModal" class="">
      <div class="relative p-4 w-full h-full flex items-center justify-center " style={{width:'40%'}}>
          <div class="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5 w-full" >
          {
              loading === false ?
              <button onClick={deleteModalHandler} type="button" class="text-gray-900 absolute top-2 right-2 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="deleteModal">
              <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
              <span class="sr-only">Close modal</span>
          </button>
          :
          <button  type="button" class="cursor-not-allowed text-gray-900 absolute top-2 right-2 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="deleteModal">
          <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          <span class="sr-only">Close modal</span>
      </button>
          }
              <p className='flex justify-center items-center mb-4'>
              <p class=" text-gray-900 css-7vq5hu mr-2" style={{fontSize:'20px'}}>
                
               Are you sure you want to delete the message from {fullName}? </p>
               <span className='items'></span>
              </p>
  
             
        
                {
                  loading === false?
                  <button onClick={deletemessage} className="page-book button button-main mt-6"style={{background:'red',color:'white'}} >   <span>Delete </span>
               </button>
               :
               <button  className="page-book button button-main mt-6 cursor-not-allowed"style={{background:'red',color:'white',cursor:'not-allowed'}} >   <span>Deleting </span>
               </button>
                }
  
  {
                  loading === false?
                  <button onClick={deleteModalHandler} className="page-book button button-main mt-3 border-2 border" style={{background:'white',color:'black'}}>   <span>Cancel </span>
               </button>
               :
               <button  className="page-book button button-main mt-3 border-2 border cursor-not-allowed" style={{background:'white',color:'black',cursor:'not-allowed'}}>   <span>Cancel </span>
               </button>
                }
              
       
           
          </div>
      </div>
    </div>
  }
  <div class=" -800 py-8">
      <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="flex flex-col md:flex-row -mx-4">
              <div class="md:flex-1  px-4">
                 
               
              </div>
              <div class="md:flex-1 px-4">
               
                <div className='flex items-center gap-2 mb-4'>
                <h2 class="text-2xl font-bold text-gray-800  ">{fullName}</h2>
  
            
                </div>
             
  
                 
                  <div class="flex mb-4">
                      <div class="mr-4">
                          <span class="font-bold text-gray-700 ">Email: </span>
                          <p class="text-gray-600 " target='_blank' >{email}</p>
                      </div>
                      <div>
                          <span class="font-bold text-gray-700 ">Message : </span>
                          <span class="text-gray-600 ">{message}</span>
                      </div>
                  </div>
                
                  
                  <div class="mb-4 flex items-center gap-2">
                      <div class="flex items-center mt-2">
                      <span class="font-bold text-gray-700 "> Time: </span>
  
                          <span class=" text-gray-600 pl-2 "> {convertTimestampToDate(createdTime)} </span>
                      </div>
                  </div>
              
  
              
                 
                
                 
              </div>
          </div>
      </div>
  </div>
  
  </main>
          )
      })
  :
  <div className='mx-[12px] h-full flex-none transition-all md:pr-2  '>
  <div className="sc-dced6a82-0 fEUgVc">
    <div className="sc-ab4f884-0 gMSCii">
      <img src="https://cdn.arc.dev/images/developer-dashboard/jobs-empty-case.svg" />
      <div width="240px" className="sc-ab4f884-1 jVlTeU">
        <p className='items mt-6'>
          You haven’t recieved any messages
          <br />
        </p>
      </div>
    </div>
  </div>
  
  </div>
  }
      </div>


}

 </div>
    </div>
  )
}
