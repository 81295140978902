import React, { useState } from 'react'
import Nav from './nav'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../firebase'
import { useHistory,Link } from 'react-router-dom/cjs/react-router-dom'


export default function Login() {
  const [pop,setpop] = useState(false)
  const [email,setemail] = useState(false)
  const [password,setpassword] = useState(false)
  const [isLoading,setisLoading] = useState(false)
  const [registrationError,setregistrationError] = useState('')
  let history = useHistory()
    function emailSet(e){
      setemail(e)

    }
    function passwordSet(e){
        setpassword(e)
    }

    function signIn(e) {
      e.preventDefault();
    
     if(email === 'admin@admin.com'||email === 'yonas@gmail.com'){
     
            signInWithEmailAndPassword(auth, email, password).then(setisLoading(true))
            .then(() => {
              history.push("/admin");
          })
          .catch((error) => {
            setisLoading(false)
              // Handle specific error cases
              if (error.code === "auth/user-not-found") {
                  setregistrationError('User not found.')
              } else if (error.code === 'auth/invalid-credential') {
                setregistrationError('Invalid login credentials');
            } else if (error.code === "auth/invalid-login-credentials") {
              setregistrationError('Invalid login credentials');
          } else if (error.code === "auth/network-request-failed") {
                setregistrationError('Check your Internet Connection');
    
              }
              else if (error.code === "auth/invalid-email") {
                setregistrationError('Invalid login credentials');
    
              }
               else {
                  setregistrationError(error.message);
              }
          });
        
        }
      
    else{
      setregistrationError('Invalid login credentials');
      setisLoading(false)

    }
    
     
  }
  return (
    <div className='bg-gradient-to-r from-stone-50 to-green-300'>
     
        <div className="min-h-screen  flex flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div className="sm:mx-auto sm:w-full sm:max-w-md">
    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 css-7vq5hu">
      Andnet Log In
    </h2>
   
  </div>
  <div className="mt-8 mx-auto  max-w-md">
    
    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 mobLogin">
    {
            registrationError  &&
            <div style={{marginTop:'-25px'}} class="mb-6 flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
  <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
  <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>{registrationError}</span>

</div>
          }
      <form onSubmit={signIn} className="space-y-6" action="submit" >
        <div>
        <input
            onChange={e=>emailSet(e.target.value)}
            type="email"
            required
            placeholder={`abebe@gmail.com`}
            className="input book-input input-field-signUp "style={{borderBottom:'1px solid black',backgroundColor:'white'}}
             />
          <input
            onChange={e=>passwordSet(e.target.value)}
            type="password"
            required
            placeholder={`**********`}
            className="input book-input input-field-signUp mt-5 "style={{borderBottom:'1px solid black',backgroundColor:'white'}}
             />
        </div>
      
        <div>
     {
      isLoading === false ?
      <button
      class="w-full   shadow-sm rounded-lg py-3 border-btn mx-auto text-white bg-gray-950 flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline">
  
      <span class="ml-4">
          Sign In
      </span>
  </button>
  :
  <button
  class="cursor-not-allowed w-full   shadow-sm rounded-lg py-3 border-btn mx-auto text-white bg-gray-950 flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline">

  <span class="ml-4">
      Loading
  </span>
</button>
     }
        </div>
      </form>
     
    </div>
  </div>
</div>

    </div>
  )
}
