import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Nav from './nav'
import { auth, fireStore } from '../firebase'
import Chart from './chart'
import { collection, doc, onSnapshot } from 'firebase/firestore'
import { onAuthStateChanged } from 'firebase/auth'
export default function Admin() {
  let [pop,setPop] = useState(false)
  let [stats,setstats] = useState([])
  let [fabrics,setfabrics] = useState([])
  let [accessories,setaccessories] = useState([])
  let [garments,setgarments] = useState([])
  let [packagings,setpackagings] = useState([])
  let [finishingProducts,setfinishingProducts] = useState([])
  let [uid,setUid] = useState()
  let [User,setUser] = useState()
  let [email,setemail] = useState()

  let [timeLine,settimeLine] = useState('total')

  let [timeLineModal,settimeLineModal] = useState(false)

  function popHandler(){
    if(pop === true){
        setPop(false)
    }
    else{
        setPop(true)
    }
}
function shortenNumber(number) {
 if(number){
  if (number >= 1_000_000_000) {
    return (number / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
  } else if (number >= 1_000_000) {
    return (number / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (number >= 1_000) {
    return (number / 1_000).toFixed(1).replace(/\.0$/, "") + "k";
  } else {
    return number.toString();
  }
 }
 return 0
}



useEffect(() => {
  // Document and Collection References
  const statDoc = doc(fireStore, 'Andnet', 'stats');
  const fabricDoc = collection(fireStore, 'Fabrics');
  const accessoriesDoc = collection(fireStore, 'Accessories');
  const garmentDoc = collection(fireStore, 'Garments');
  const packagingDoc = collection(fireStore, 'Packagings');
  const finishingProductsDoc = collection(fireStore, 'FinishingProducts');

  // Subscribe to Fabric Collection
  const unsubscribeFabric = onSnapshot(fabricDoc, (snapshot) => {
    const temp = snapshot.docs.map((doc) => ({ ...doc.data() }));
    setfabrics(temp);
  });

  // Subscribe to Accessories Collection
  const unsubscribeAccessories = onSnapshot(accessoriesDoc, (snapshot) => {
    const temp = snapshot.docs.map((doc) => ({ ...doc.data() }));
    setaccessories(temp);
  });
  const unsubscribeFinishingProducts = onSnapshot(finishingProductsDoc, (snapshot) => {
    const temp = snapshot.docs.map((doc) => ({ ...doc.data() }));
    setfinishingProducts(temp);
  });
  const unsubscribePackagings = onSnapshot(packagingDoc, (snapshot) => {
    const temp = snapshot.docs.map((doc) => ({ ...doc.data() }));
    setpackagings(temp);
  });

  const unsubscribeGarment = onSnapshot(garmentDoc, (snapshot) => {
    const temp = snapshot.docs.map((doc) => ({ ...doc.data() }));
    setgarments(temp);
  });

  // Subscribe to Stats Document
  const unsubscribeStats = onSnapshot(statDoc, (snapshot) => {
    if (snapshot.exists()) {
      setstats([{ ...snapshot.data() }]);
    } else {
      console.error("No such document!");
    }
  });



  // Cleanup all subscriptions
  return () => {
    unsubscribeFabric();
    unsubscribeAccessories();
    unsubscribeStats();
    unsubscribeGarment()
    unsubscribeFinishingProducts()
    unsubscribePackagings()
  };
}, [fireStore]);

function timelineHandler(){
  if(timeLineModal === true){
      settimeLineModal(false)
  }
  else{
      settimeLineModal(true)
  }
}

function timelineSelect(time){
  settimeLine(time)

}


useEffect(() => {
  // Fetch the courses data from the courses collection with the courseId

  const unsubscribe = onAuthStateChanged(auth, (user) => {
    if (user) {
      const uid = user.uid;
      setUid(uid);
      setemail(user.email);
      
    

      // Fetching user details from Firestore
      const userRef = doc(fireStore, "Andnet", uid);
      onSnapshot(userRef, (snapshot) => {
        let userDetails = [];

        userDetails.push({ ...snapshot.data(), id: snapshot.id });
        setUser(userDetails);  // Assuming setUser is a state setter

      });
    }
  });

  return () => {
    unsubscribe();
  };
}, []);
  return (
    <div cla>
    <Nav compType='1' navigationPop={pop}/>
{
  pop === false &&
  <div className='h-full w-full bg-lightPrimary dark:!bg-navy-900 px-5' onClick={popHandler}>

{
  stats.map(i=>{
    const {totalExpense,totalMoneySpentOnFabrics,totalMoneySpentOnAccessories,totalMoneySpentOnPackaging,totalMoneySpentOnFinishingProducts} = i
    return(
      <main className='mx-[12px] h-full flex-none transition-all md:pr-2   relative mt-4'>

<nav className="sticky top-8 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl ">
      
  <div className="flex items-center">
   
   
   
   
    
    <Link
        className="font-bold capitalize hover:text-navy-700  css-7vq5hu"
        to="/admin"
      >
   {email === 'yonas@gmail.com' ? 'Welcome Yonas!': 'Welcome Estifanos!'}
      </Link>
  </div>
</nav>
<div className=" mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2 w-full ">
  <div className='w-full'>
    <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
      <div style={{border:'0.1px solid black'}} className="border !z-5 relative flex flex-col rounded-md  bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800  dark:shadow-none !flex-row flex-grow items-center rounded-md ">
        <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
          <div className="rounded-full bg-lightPrimary p-3 ">
            <span className="flex items-center text-brand-500 ">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 24 24"
                className="h-7 w-7"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z" />
              </svg>
            </span>
          </div>
        </div>
        <div className="h-50 ml-4 flex w-auto flex-col justify-center">
          <p className="header" style={{fontSize:'20px'}}>{timeLine === 'today' ? `Today's Expense` : 'Total Expense'}</p>
          <h4 className="para mt-2 text-gray-700 ">
            {shortenNumber(totalExpense)} ETB
          </h4>
        </div>
      </div>
      <div style={{border:'0.1px solid black'}} className="border !z-5 relative flex flex-col rounded-md  bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800  dark:shadow-none !flex-row flex-grow items-center rounded-md ">
        <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
          <div className="rounded-full bg-lightPrimary p-3 ">
            <span className="flex items-center text-brand-500 ">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 512 512"
                className="h-6 w-6"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M298.39 248a4 4 0 002.86-6.8l-78.4-79.72a4 4 0 00-6.85 2.81V236a12 12 0 0012 12z" />
                <path d="M197 267a43.67 43.67 0 01-13-31v-92h-72a64.19 64.19 0 00-64 64v224a64 64 0 0064 64h144a64 64 0 0064-64V280h-92a43.61 43.61 0 01-31-13zm175-147h70.39a4 4 0 002.86-6.8l-78.4-79.72a4 4 0 00-6.85 2.81V108a12 12 0 0012 12z" />
                <path d="M372 152a44.34 44.34 0 01-44-44V16H220a60.07 60.07 0 00-60 60v36h42.12A40.81 40.81 0 01231 124.14l109.16 111a41.11 41.11 0 0111.83 29V400h53.05c32.51 0 58.95-26.92 58.95-60V152z" />
              </svg>
            </span>
          </div>
        </div>
        <div className="h-50 ml-4 flex w-auto flex-col justify-center">
          <p className="header" style={{fontSize:'20px'}}>
          Accessories Spending
          </p>
          <h4 className="para mt-2 text-gray-700 ">
           {shortenNumber(totalMoneySpentOnAccessories)} ETB
          </h4>
        </div>
      </div>
      <div style={{border:'0.1px solid black'}} className="border !z-5 relative flex flex-col rounded-md  bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800  dark:shadow-none !flex-row flex-grow items-center rounded-md ">
        <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
          <div className="rounded-full bg-lightPrimary p-3 ">
          <span className="flex items-center text-brand-500 ">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 512 512"
                className="h-6 w-6"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M298.39 248a4 4 0 002.86-6.8l-78.4-79.72a4 4 0 00-6.85 2.81V236a12 12 0 0012 12z" />
                <path d="M197 267a43.67 43.67 0 01-13-31v-92h-72a64.19 64.19 0 00-64 64v224a64 64 0 0064 64h144a64 64 0 0064-64V280h-92a43.61 43.61 0 01-31-13zm175-147h70.39a4 4 0 002.86-6.8l-78.4-79.72a4 4 0 00-6.85 2.81V108a12 12 0 0012 12z" />
                <path d="M372 152a44.34 44.34 0 01-44-44V16H220a60.07 60.07 0 00-60 60v36h42.12A40.81 40.81 0 01231 124.14l109.16 111a41.11 41.11 0 0111.83 29V400h53.05c32.51 0 58.95-26.92 58.95-60V152z" />
              </svg>
            </span>
          </div>
        </div>
        <div className="h-50 ml-4 flex w-auto flex-col justify-center">
          <p className="header" style={{fontSize:'20px'}}>Fabrics Spending 
          </p>
          <h4 className="para mt-2 text-gray-700 ">
            {shortenNumber(totalMoneySpentOnFabrics)} ETB
          </h4>
        </div>
      </div>
      <div style={{border:'0.1px solid black'}} className="border !z-5 relative flex flex-col rounded-md  bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800  dark:shadow-none !flex-row flex-grow items-center rounded-md ">
        <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
          <div className="rounded-full bg-lightPrimary p-3 ">
            <span className="flex items-center text-brand-500 ">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 512 512"
                className="h-6 w-6"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M298.39 248a4 4 0 002.86-6.8l-78.4-79.72a4 4 0 00-6.85 2.81V236a12 12 0 0012 12z" />
                <path d="M197 267a43.67 43.67 0 01-13-31v-92h-72a64.19 64.19 0 00-64 64v224a64 64 0 0064 64h144a64 64 0 0064-64V280h-92a43.61 43.61 0 01-31-13zm175-147h70.39a4 4 0 002.86-6.8l-78.4-79.72a4 4 0 00-6.85 2.81V108a12 12 0 0012 12z" />
                <path d="M372 152a44.34 44.34 0 01-44-44V16H220a60.07 60.07 0 00-60 60v36h42.12A40.81 40.81 0 01231 124.14l109.16 111a41.11 41.11 0 0111.83 29V400h53.05c32.51 0 58.95-26.92 58.95-60V152z" />
              </svg>
            </span>
          </div>
        </div>
        <div className="h-50 ml-4 flex w-auto flex-col justify-center">
          <p className="header" style={{fontSize:'20px'}}>
          Finishing Products Spending
          </p>
          <h4 className="para mt-2 text-gray-700 ">
           {shortenNumber(totalMoneySpentOnFinishingProducts)} ETB
          </h4>
        </div>
      </div>
      <div style={{border:'0.1px solid black'}} className="border !z-5 relative flex flex-col rounded-md  bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800  dark:shadow-none !flex-row flex-grow items-center rounded-md ">
        <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
          <div className="rounded-full bg-lightPrimary p-3 ">
          <span className="flex items-center text-brand-500 ">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 512 512"
                className="h-6 w-6"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M298.39 248a4 4 0 002.86-6.8l-78.4-79.72a4 4 0 00-6.85 2.81V236a12 12 0 0012 12z" />
                <path d="M197 267a43.67 43.67 0 01-13-31v-92h-72a64.19 64.19 0 00-64 64v224a64 64 0 0064 64h144a64 64 0 0064-64V280h-92a43.61 43.61 0 01-31-13zm175-147h70.39a4 4 0 002.86-6.8l-78.4-79.72a4 4 0 00-6.85 2.81V108a12 12 0 0012 12z" />
                <path d="M372 152a44.34 44.34 0 01-44-44V16H220a60.07 60.07 0 00-60 60v36h42.12A40.81 40.81 0 01231 124.14l109.16 111a41.11 41.11 0 0111.83 29V400h53.05c32.51 0 58.95-26.92 58.95-60V152z" />
              </svg>
            </span>
          </div>
        </div>
        <div className="h-50 ml-4 flex w-auto flex-col justify-center">
          <p className="header" style={{fontSize:'20px'}}>Packaging Spending 
          </p>
          <h4 className="para mt-2 text-gray-700 ">
            {shortenNumber(totalMoneySpentOnPackaging)} ETB
          </h4>
        </div>
      </div>
      <div style={{border:'0.1px solid black'}} className="border !z-5 relative flex flex-col rounded-md  bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800  dark:shadow-none !flex-row flex-grow items-center rounded-md ">
        <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
          <div className="rounded-full bg-lightPrimary p-3 ">
            <span className="flex items-center text-brand-500 ">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 24 24"
                className="h-6 w-6"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
              </svg>
            </span>
          </div>
        </div>
        <div className="h-50 ml-4 flex w-auto flex-col justify-center">
          <p className="header" style={{fontSize:'20px'}}>
            Total Accessories
          </p>
          <h4 className="para mt-2 text-gray-700 ">
           {accessories.length}
          </h4>
        </div>
      </div>
      <div style={{border:'0.1px solid black'}} className="border !z-5 relative flex flex-col rounded-md  bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800  dark:shadow-none !flex-row flex-grow items-center rounded-md ">
        <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
          <div className="rounded-full bg-lightPrimary p-3 ">
            <span className="flex items-center text-brand-500 ">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 24 24"
                className="h-7 w-7"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M4 9h4v11H4zM16 13h4v7h-4zM10 4h4v16h-4z" />
              </svg>
            </span>
          </div>
        </div>
        <div className="h-50 ml-4 flex w-auto flex-col justify-center">
          <p className="header" style={{fontSize:'20px'}}>    Total Fabrics
          </p>
          <h4 className="para mt-2 text-gray-700 ">
           {fabrics.length}
          </h4>
        </div>
      </div>
      
      <div style={{border:'0.1px solid black'}} className="border !z-5 relative flex flex-col rounded-md  bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800  dark:shadow-none !flex-row flex-grow items-center rounded-md ">
        <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
          <div className="rounded-full bg-lightPrimary p-3 ">
            <span className="flex items-center text-brand-500 ">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 512 512"
                className="h-6 w-6"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M208 448V320h96v128h97.6V256H464L256 64 48 256h62.4v192z" />
              </svg>
            </span>
          </div>
        </div>
        <div className="h-50 ml-4 flex w-auto flex-col justify-center">
          <p className="header" style={{fontSize:'20px'}}>
            Total Garments
          </p>
          <h4 className="para mt-2 text-gray-700 ">
            {garments.length}
          </h4>
        </div>
      </div>
      <div style={{border:'0.1px solid black'}} className="border !z-5 relative flex flex-col rounded-md  bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800  dark:shadow-none !flex-row flex-grow items-center rounded-md ">
        <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
          <div className="rounded-full bg-lightPrimary p-3 ">
            <span className="flex items-center text-brand-500 ">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 512 512"
                className="h-6 w-6"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M208 448V320h96v128h97.6V256H464L256 64 48 256h62.4v192z" />
              </svg>
            </span>
          </div>
        </div>
       
        <div className="h-50 ml-4 flex w-auto flex-col justify-center">
          <p className="header" style={{fontSize:'20px'}}>
            Total Packagings
          </p>
          <h4 className="para mt-2 text-gray-700 ">
            {packagings.length}
          </h4>
        </div>
      </div>
      <div style={{border:'0.1px solid black'}} className="border !z-5 relative flex flex-col rounded-md  bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800  dark:shadow-none !flex-row flex-grow items-center rounded-md ">
        <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
          <div className="rounded-full bg-lightPrimary p-3 ">
            <span className="flex items-center text-brand-500 ">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 512 512"
                className="h-6 w-6"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M208 448V320h96v128h97.6V256H464L256 64 48 256h62.4v192z" />
              </svg>
            </span>
          </div>
        </div>
        <div className="h-50 ml-4 flex w-auto flex-col justify-center">
          <p className="header" style={{fontSize:'20px'}}>
            Total Finishing Products
          </p>
          <h4 className="para mt-2 text-gray-700 ">
            {finishingProducts.length}
          </h4>
        </div>
      
      </div>
    </div>
   
  
  </div>

</div>

</main>
    )
  })
}
</div>

}


    </div>
  )
}
