import { setDoc,doc, onSnapshot, collection, getDoc,serverTimestamp } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { auth, fireStore } from '../firebase'
import { v4 } from 'uuid'
import Aws from'aws-sdk'
import Nav from './nav'
import { onAuthStateChanged } from 'firebase/auth'
export default function Accessory() {
    const [addNewAccessory,setaddNewAccessory]= useState(false)
    const [AccessoryName,setAccessoryName]= useState('')
    const [totalPrice,settotalPrice]= useState()
    const [email,setemail]= useState()

    const [AccessoryNameError,setAccessoryNameError]= useState('')
    const [AccessoryType,setAccessoryType]= useState('')
    const [AccessoryTypeError,setAccessoryTypeError]= useState('')
    const [AccessoryColor,setAccessoryColor]= useState('')
    const [AccessoryColorError,setAccessoryColorError]= useState('')
    const [accessorySize,setaccessorySize]= useState('')
    const [AccessorySizeError,setAccessorySizeError]= useState('')
    const [AccessoryHeight,setAccessoryHeight]= useState('')
    const [AccessoryHeightError,setAccessoryHeightError]= useState('')
    const [AccessoryPhoto,setAccessoryPhoto]= useState([])
    const [AccessoryPhotoError,setAccessoryPhotoError]= useState('')
    const [supplierName,setsupplierName]= useState('')
    const [supplierNameError,setsupplierNameError]= useState('')
    const [supplierPhoneNumber,setsupplierPhoneNumber]= useState('')
    const [supplierPhoneNumberError,setsupplierPhoneNumberError]= useState('')
    const [numberOfItem,setnumberOfItem]= useState('')
    const [numberOfItemError,setnumberOfItemError]= useState('')
    const [pricePerItem,setpricePerItem]= useState('')
    const [pricePerItemError,setpricePerItemError]= useState('')
    const [reciept,setreciept]= useState([])
    const [recieptError,setrecieptError]= useState('')
    const [notes,setnotes]= useState('')
    const [notesError,setnotesError]= useState('')
    const [garmentApplication,setgarmentApplication]= useState('')
    const [garmentApplicationError,setgarmentApplicationError]= useState('')
    const [garmentIntendedFor,setgarmentIntendedFor]= useState('')
    const [garmentIntendedForError,setgarmentIntendedForError]= useState('')
    
    const [uploadProgress,setUploadProgress] = useState()
    const [loading,setloading] = useState(true)
    const [success,setsuccess] = useState()
    const [error,setError] = useState()
    const [progress,setprogress] = useState()
    const [Accessory,setAccessory] = useState([])
    let [pop,setPop] = useState(false)


    function popHandler(){
      if(pop === true){
          setPop(false)
      }
      else{
          setPop(true)
      }
  }

  const formatNumber = (amount) => {
    // Ensure the input is a number, and format it with commas
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,  // Limit to two decimal places
    }).format(amount);
  };


    function AccessoryNameSet(e){
        setAccessoryName(e)
        if(e.length ===0){
            setAccessoryNameError('error')
        }
        else{
            setAccessoryNameError('')

        }

    }

    function addNewAccessoryModal(){
      setloading(true)
      setTimeout(() => {
        if(addNewAccessory === true){
          setloading(false)
          setaddNewAccessory(false)
        }
        else{
          setloading(false)
          setaddNewAccessory(true)
        }
        
      }, 3000);
    }
  
    function AccessoryColorSet(e){
        setAccessoryColor(e)
        if(e.length ===0){
            setAccessoryColorError('error')
        }
        else{
            setAccessoryColorError('')

        }

    }
    function AccessorySizeSet(e){
        setaccessorySize(e)
        if(e.length ===0){
            setAccessorySizeError('error')
        }
        else{
            setAccessorySizeError('')

        }

    }
    function AccessoryHeightSet(e){
        setAccessoryHeight(e)
        if(e.length ===0){
            setAccessoryHeightError('error')
        }
        else{
            setAccessoryHeightError('')

        }

    }
    function AccessoryPhotoSet(file){
    
      if (file) {
        setAccessoryPhoto(file); 
       
        
        
        const reader = new FileReader();
       
        reader.readAsDataURL(file); 
      }
        if(file && file.length ===0){
            setAccessoryPhotoError('error')
        }
        else{
            setAccessoryPhotoError('')

        }

    }
    function supplierNameSet(e){
        setsupplierName(e)
        if(e.length ===0){
            setsupplierNameError('error')
        }
        else{
            setsupplierNameError('')

        }

    }
    function supplierPhoneNumberSet(e){
        setsupplierPhoneNumber(e)
        if(e.length ===0){
            setsupplierPhoneNumberError('error')
        }
        else{
            setsupplierPhoneNumberError('')

        }

    }

    function numberOfItemsSet(e){
        setnumberOfItem(e)
        if(e.length ===0){
            setnumberOfItemError('error')
        }
        else{
            setnumberOfItemError('')

        }

    }
    function notesSet(e){
        setnotes(e)
      

    }
    Aws.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, 
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, 
        region: 'us-east-1', // Your S3 bucket region
      })
    function pricePerItemSet(e){
        setpricePerItem(e)
        if(e.length ===0){
            setpricePerItemError('error')
        }
        else{
            setpricePerItemError('')

        }

    }
    function recieptSet(file){
         if (file) {
        setreciept(file); 
       
        
        
        const reader = new FileReader();
       
        reader.readAsDataURL(file); 
      }
        if(file && file.length ===0){
            setrecieptError('error')
        }
        else{
            setrecieptError('')

        }

    }
 

  useEffect(()=>{
    let totalPrice = 0
    
    totalPrice = pricePerItem * numberOfItem
  
    settotalPrice((totalPrice))
   },[pricePerItem,numberOfItem])

   async function addAccessory() {
    const userDoc = doc(fireStore, 'Andnet', 'stats');
    const statsData = await getDoc(userDoc);
    const currentTotalExpense = statsData.data().totalExpense || 0;
    const currentTotalMoneySpentOnAccessories = statsData.data().totalMoneySpentOnAccessories || 0;

    const totalPriceSpent = parseFloat(totalPrice);
    const TotalExpense = isNaN(totalPriceSpent) ? currentTotalExpense : currentTotalExpense + totalPriceSpent;
    const TotalMoneySpentOnAccessories = isNaN(totalPriceSpent) ? currentTotalMoneySpentOnAccessories : currentTotalMoneySpentOnAccessories + totalPriceSpent;
    
    let data = {
      accessoryName: AccessoryName,
      garmentApplication: garmentApplication,
      garmentIntendedFor:garmentIntendedFor,
      accessoryColor: AccessoryColor,
      accessorySize: accessorySize,
      accessoryPhoto: AccessoryPhoto,  
      notes: notes,
      createdTime:serverTimestamp(),
      stock: numberOfItem,
      supplierName: supplierName,
      supplierPhoneNumber: supplierPhoneNumber,
      numberOfItem: numberOfItem,
      pricePerItem: pricePerItem,
      reciept: reciept, 
  };

    // Validate inputs
    if (!AccessoryName) {
        setAccessoryNameError('error');
        return;
    } else {
        setAccessoryNameError('');
    }

   
   
    if (!AccessoryColor) {
        setAccessoryColorError('error');
        return;
    } else {
        setAccessoryColorError('');
    }
    if (!accessorySize) {
        setAccessorySizeError('error');
        return;
    } else {
        setAccessorySizeError('');
    }

    if (!AccessoryPhoto) {
        setAccessoryPhotoError('error');
        return;
    } else {
        setAccessoryPhotoError('');
    }

    if (!supplierName) {
        setsupplierNameError('error');
        return;
    } else {
        setsupplierNameError('');
    }

    if (!supplierPhoneNumber) {
        setsupplierPhoneNumberError('error');
        return;
    } else {
        setsupplierPhoneNumberError('');
    }

    if (!numberOfItem) {
        setnumberOfItemError('error');
        return;
    } else {
        setnumberOfItemError('');
    }

    if (!pricePerItem) {
        setpricePerItemError('error');
        return;
    } else {
        setpricePerItemError('');
    }

    if (!reciept) {
        setrecieptError('error');
        return;
    } else {
        setrecieptError('');
    }

    try {
        // Initialize loading and progress
        setloading(true);
        setprogress(0);

        const s3 = new Aws.S3();
        const uploadedAccessoryImageUrls = []; // To store the URLs of Accessory photos
        const uploadedReceiptImageUrls = []; // To store the URLs of receipt photos

        // Function to upload a single file
        const uploadFile = async (file, folderName, imageUrlsArray) => {
            if (!file) {
                console.log(`No file provided for ${folderName}.`);
                return;
            }

            const params = {
                Bucket: 'andnet', // Replace with your bucket name
                Key: `${folderName}/${file.name}`, // Path in the bucket
                Body: file, // The file object
                ContentType: file.type, // Content type based on the file's mime type
            };

            // Start uploading the file
            const upload = s3.upload(params);

            // Track upload progress for this file
            upload.on('httpUploadProgress', (progress) => {
                const progressPercentage = Math.round((progress.loaded / progress.total) * 100);
               
                setUploadProgress(progressPercentage); // Update global progress or handle separately
            });

            // Perform the upload and wait for completion
            await new Promise((resolve, reject) => {
                upload.send((err, data) => {
                    if (err) {
                        console.log(`Error uploading file ${file.name}:`, err);
                        alert(`Error uploading ${file.name}`);
                        reject(err);
                    } else {
                        console.log(`File ${file.name} uploaded successfully:`, data);
                        imageUrlsArray.push(data.Location); // Store the uploaded file URL
                        resolve();
                    }
                });
            });
        };

        // Upload Accessory photo and receipt photo
        await uploadFile(AccessoryPhoto, 'Accessory', uploadedAccessoryImageUrls);
        await uploadFile(reciept, 'receipt', uploadedReceiptImageUrls);

        // After successful upload, add Accessory data to Firestore
        data.accessoryPhoto = uploadedAccessoryImageUrls[0]; // Store the URL of the Accessory photo
        data.reciept = uploadedReceiptImageUrls[0]; // Store the URL of the receipt photo

        await setDoc(doc(fireStore, 'Accessories', AccessoryName[0]  + AccessoryName[AccessoryName.length - 1]  + v4().slice(0, 8)), data);
        await setDoc(userDoc, { totalExpense: TotalExpense,totalMoneySpentOnAccessories:TotalMoneySpentOnAccessories }, { merge: true });

        setloading(false);
        setsuccess(true);
        setaddNewAccessory(false)
        setreciept()
        setFabricPhoto()

    } catch (error) {
        console.error('An error occurred while adding the Accessory:', error);
        setloading(false);
        setError((prevErrors) => ({
            ...prevErrors,
            general: 'An error occurred while adding the Accessory. Please try again.',
        }));
    }
}

useEffect(() => {
      // Fetch the courses data from the courses collection with the courseId
    
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setemail(user.email)
        
    
         
        }
      });
    
      return () => {
        unsubscribe();
        setloading(false)

      };
    }, []);
useEffect(() => {
  const unsubscribe = onSnapshot(
      collection(fireStore, 'Accessories'),
      (docs) => {
          let temp = [];
          docs.forEach(snapshot => {
              // Push data into the temporary array with AccessoryId
              temp.push({ ...snapshot.data(), accessoryId: snapshot.id });
          });
          setAccessory(temp); // Update the state with the fetched data
          setloading(false);  // Set loading to false when data is fetched
      },
      (err) => {
          setError(err.message);  // Handle any error while fetching
          setloading(false);
      }
  );

  // Cleanup on unmount
  return () => unsubscribe();
}, []);


const timestampToString = (timestamp) => {
  if(timestamp){
    const date = timestamp.toDate();
    const currentDate = new Date();
    const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);
  
    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInWeek = 604800;
    const secondsInMonth = 2592000;
    const secondsInYear = 31536000;
  
    let relativeTime = '';
  
    if (timeDifferenceInSeconds < secondsInMinute) {
      relativeTime = `${timeDifferenceInSeconds}s ago`;
    } else if (timeDifferenceInSeconds < secondsInHour) {
      const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
      relativeTime = `${minutes}m ago`;
    } else if (timeDifferenceInSeconds < secondsInDay) {
      const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
      relativeTime = `${hours}h ago`;
    } else if (timeDifferenceInSeconds < secondsInWeek) {
      const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
      relativeTime = `${days}d ago`;
    } else if (timeDifferenceInSeconds < secondsInMonth) {
      const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
      relativeTime = `${weeks}w ago`;
    } else if (timeDifferenceInSeconds < secondsInYear) {
      const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
      relativeTime = `${months}mo ago`;
    } else {
      const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
      relativeTime = `${years}y ago`;
    }
  
    return relativeTime;
  }
  
  };
  return (
    <div>
   <div c>
   <Nav compType='2' popProp={pop}/>
<div className='h-full w-full bg-lightPrimary dark:!bg-navy-900 '>

<main className='mx-[12px] h-full flex-none transition-all md:pr-2  mt-4' onClick={popHandler}>
<nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 ">
  <div className="ml-[6px]">
   
    <p className="shrink text-[33px] capitalize text-navy-700 ">
    
    </p>
  </div>
  <div className="flex items-center">
   
   
    <div className="relative flex">
      <div className="flex">
    </div>
    </div>
        
 
    
    <div className="relative flex ml-2 ">
      <div className="flex">
     
      <Link
        className="font-bold capitalize hover:text-navy-700 dark:hover:text-white css-7vq5hu"
        to="/admin/Accessory"
      >
       Accessory List {uploadProgress && uploadProgress + '%'}

      </Link>
      </div>
    
    </div>
  </div>
</nav>

{
   loading === true ?
   <div className='relative flex justify-center items-center h-[90vh]'>
   <div className="typewriter">
     <div className="slide">
       <i />
     </div>
     <div className="paper" />
     <div className="keyboard" />
   </div>
   </div>
   :
   (Accessory.length === 0  || addNewAccessory === true)&&email != 'yonas@gmail.com' ?
   <div className="AccessoryDetails page-form  mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2 px-8">
{
 Accessory.length !==0 && email != 'yonas@gmail.com' &&
 <button className="page-book button button-main absolute left-10 top-24" style={{backgroundColor:'red',width:'auto',zIndex:10000}} onClick={addNewAccessoryModal}>
             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
 <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
</svg>
             </button>
}
       <div className="page-inputs mt-10">
       <p className="header" style={{fontSize:'25px'}}>
             Accessory Details 
           </p>    
   <div class="relative z-0 w-full mb-3 group">

         <input
           type="text"
           name='Accessory Name'
           onChange={e=>AccessoryNameSet(e.target.value)}
           placeholder=''
           style={{borderBottom:'1px solid black',borderRadius:'0'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
         />
       <label for="Accessory Name" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Accessory Name </label>
       </div>

        
        {
           AccessoryNameError === 'error' &&
           <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
 <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
 <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
         }  
        
         <input
           type="color"
           placeholder="Accessory Color"
           autoComplete="Accessory Color"
           onChange={e=>AccessoryColorSet(e.target.value)}

           className="input book-input "style={{borderBottom:'1px solid black'}}
            />
             {
           AccessoryColorError === 'error' &&
           <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
 <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
 <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
         }

<div class="relative z-0 w-full mb-5 group">

<input
  type="text"
  name='Accessory Size'
  onChange={e=>AccessorySizeSet(e.target.value)}
  placeholder=''
  style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
/>
<label for="Accessory Size" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Accessory Size </label>
</div>
           
         
               {
           AccessorySizeError === 'error' &&
           <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
 <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
 <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
         }
       

<div className="file-upload-container flex flex-col">
  <input
    id="accessory-photo"
    type="file"
    onChange={e =>AccessoryPhotoSet(e.target.files[0])}
    className="file-input"
  />
  <div className="custom-text mb-2">
    Accessory Photo
  </div>
<p style={{fontSize:'13px',marginLeft:'5px'}}>{AccessoryPhoto && AccessoryPhoto.name}</p>

</div>

             {
           AccessoryPhotoError === 'error' &&
           <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
 <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
 <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
         }


<div class="relative z-0 w-full mb-5 group">

<input
  type="text"
  name='Note'
  onChange={e=>notesSet(e.target.value)}
  placeholder=''
  style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
/>
<label for="Note" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Additional Notes (Optional) </label>
</div>
            
            

<p className="header" style={{fontSize:'25px'}}>
Supplier's Details
           </p>  

           <div class="relative z-0 w-full my-3 group">

<input
  type="text"
  name='supplier Name'
  onChange={e=>supplierNameSet(e.target.value)}
  placeholder=''
  style={{borderBottom:'1px solid black',borderRadius:'0'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
/>
<label for="supplier Name" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Supplier's Name</label>
</div>
        
            {
           supplierNameError === 'error' &&
           <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
 <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
 <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
         }

<div class="relative z-0 w-full  group  mb-5">

<input
  type="tel"
  name='supplier Phone'
  onChange={e=>supplierPhoneNumberSet(e.target.value)}
  placeholder=''
  style={{borderBottom:'1px solid black',borderRadius:'0'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
/>
<label for="supplier Phone" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Supplier's Phone Number</label>
</div>
            
            {
           supplierPhoneNumberError === 'error' &&
           <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
 <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
 <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
         }

<p className="header" style={{fontSize:'25px'}}>
Purchase Details
           </p>  

       {    totalPrice != 0 &&
            <p className="items">

              Total Price =>  { pricePerItem +  ' x '+ numberOfItem + ' = '+formatNumber(totalPrice)} ETB
            </p>}

            <div class="relative z-0 w-full mb-3 group">

<input
  type="number"
  name='Number Of Items'
  onChange={e=>numberOfItemsSet(e.target.value)}
  placeholder=''
  style={{borderBottom:'1px solid black',borderRadius:'0'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
/>
<label for="Number Of Items" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Number of pieces bought</label>
</div>
            {
           numberOfItemError === 'error' &&
           <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
 <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
 <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
         }

<div class="relative z-0 w-full mb-3 group">

<input
  type="number"
  name='Price Per Item'
  onChange={e=>pricePerItemSet(e.target.value)}
  placeholder=''
  style={{borderBottom:'1px solid black',borderRadius:'0'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
/>
<label for="Price Per Item" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Purchase Price(per item)</label>
</div>
              
            {
           pricePerItemError === 'error' &&
           <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
 <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
 <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
         }
            <div className="file-upload-container flex">
  <input
    id="accessory-photo"
    type="file"
    onChange={e =>recieptSet(e.target.files[0])}
    className="file-input"
  />
  <div className="custom-text">
  Reciept Photo
  </div>
<p style={{fontSize:'13px',marginLeft:'5px'}}>{reciept && reciept.name}</p>

</div>
            {
           recieptError === 'error' &&
           <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
 <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
 <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
         }
         <div className="page-lower">
       
           <div className="page-button mb-10">
             <button className="page-book button button-main" onClick={addAccessory}>
               <span>Add Accessory</span>
             </button>
             
           </div>
         </div>
       </div>
     </div>
     :
     Accessory.length != 0 ?
      <div className="mt-4   mx-auto mb-auto h-full min-h-[84vh] p-2 tablePadding " style={{overflowX:'scroll'}}>

{ email != 'yonas@gmail.com' &&<button className="page-book button button-main absolute left-10 top-24" style={{width:'auto',zIndex:10000}} onClick={addNewAccessoryModal}>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
 <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
</svg>
             </button>}
<div className="w">
<table class="w-full text-sm text-leftt text-gray-500 overflow-scroll">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
            <tr>
            <th scope="col" class="tablePadding text-center py-3">
                </th>
                <th scope="col" class="tablePadding text-center py-3">
                    Name
                </th>
                <th scope="col" class="tablePadding text-center py-3">
                    Stock
                </th>
                <th scope="col" class="tablePadding text-center py-3">
                    Price
                </th>
                <th scope="col" class="tablePadding text-center py-3">
                    Time
                </th>
                <th scope="col" class="tablePadding text-center py-3">
                    Action
                </th>
            </tr>
        </thead>
     {Accessory && Accessory.sort((a,b)=>b.createdTime-a.createdTime).map((i,index)=>{
      const{createdTime,accessoryName,accessoryPrice,stock,accessoryWidth,accessoryHeight,accessoryPhoto,garmentApplication,accessoryId,numberOfItem, pricePerItem} = i
      return(
        <tbody>
        <tr class="border-b ">
        <th scope="row" class="tablePadding text-center py-4 font-medium text-gray-900 whitespace-nowrap ">
                {index + 1}
            </th>
            <th scope="row" class="tablePadding text-center py-4 font-medium text-gray-900 whitespace-nowrap ">
                {accessoryName}
            </th>
            <td class="tablePadding text-center py-4">
            {formatNumber(stock)} piece
            </td>
            <td class="tablePadding text-center py-4">
            { formatNumber(pricePerItem  * numberOfItem)} ETB

            </td>
            <td class="tablePadding text-center py-4">
          {timestampToString(createdTime)}
            </td>
            <td class="tablePadding text-center py-4">
                <Link to={`/admin/accessory/${accessoryId}`}>
                <a  class="font-medium text-blue-600  hover:underline">{email != 'yonas@gmail.com'?'Edit':'View'}</a></Link>
            </td>
        </tr>
        </tbody>





      )
     })}
</table>

</div>

    </div>
    :
<div className='mx-[12px] h-full flex-none transition-all md:pr-2  '>
<div className="sc-dced6a82-0 fEUgVc">
  <div className="sc-ab4f884-0 gMSCii">
    <img src="https://cdn.arc.dev/images/developer-dashboard/jobs-empty-case.svg" />
    <div width="240px" className="sc-ab4f884-1 jVlTeU">
      <p className='items mt-6'>
        You haven’t added any any Accessories
        <br />
      </p>
    </div>
  </div>
</div>

</div>

}



</main>
</div>



    </div>

    </div>
  )
}
