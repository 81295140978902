import React, { Component } from "react";
import Chart from "react-apexcharts";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: "basic-bar",
          type: 'bar',
      height: 350,
        }
        ,
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: 'rounded',
          
          },
        },
        xaxis: {
          categories: ['Mon', 'Tues','Wed', 'Thur', 'Frid', 'Sat', 'Sun']
        },  dataLabels: {
          enabled: false,
        }
      },
      series: [
        {
          name: "Students",
          colors:['#F44336', 'black', '#000000ff'],

          data: [30, 40, 45, 50, 49, 60, 70]
        },
        {
            name: "Earnings",
            textColor:'#000000ff',
            color:'black',
            data: [30, 40, 45, 50, 49, 60, 70,]
          }
      ]
     
    };
  }

  render() {
    return (
        <div className="row">
          <div className="mixed-chart text-black chartMain " style={{width:'80%',margin:'auto'}}>
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="bar"
              height={'400px'}
              width={'100%'}
              
            />
          </div>
        </div>
    );
  }
}

export default App;