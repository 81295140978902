import React, { useEffect, useState } from 'react'
import Nav from './nav'
import { auth, fireStore } from '../firebase'
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { deleteDoc, doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore'
import { func } from 'prop-types'
import { onAuthStateChanged } from 'firebase/auth'

export default function Fabric() {
    let [pop,setPop] = useState(false)
    let [fabricNameEditor,setfabricNameEditor] = useState(false)
    let [numberOfItemsEditor,setnumberOfItemsEditor] = useState(false)
    let [pricePerItemEditor,setpricePerItemEditor] = useState(false)
    let [fabricColorEditor,setfabricColorEditor] = useState(false)
    let [supplierNameEditor,setsupplierNameEditor] = useState(false)
    let [supplierPhoneEditor,setsupplierPhoneEditor] = useState(false)
    const [fabricColor,setFabricColor]= useState('')
    const [fabricColorError,setFabricColorError]= useState('')
    const [fabricName,setFabricName]= useState('')
    const [fabricNameError,setFabricNameError]= useState('')
    let [deleteModal,setdeleteModal] = useState(false)
    let [loading,setLoading] = useState(false)
    let [email,setemail] = useState(false)
  const yonas = 'yonas@gmail.com'

    let [fabric,setfabric] = useState([])
    let {fabricId} = useParams()
    let history = useHistory()
useEffect(() => {
      // Fetch the courses data from the courses collection with the courseId
    
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setemail(user.email)
        
    
         
        }
      });
    
      return () => {
        unsubscribe();

      };
    }, []);

    function fabricNameSet(e){
      setFabricName(e)
      if(e.length ===0){
          setFabricNameError('error')
      }
      else{
          setFabricNameError('')

      }

  }
  function fabricColorSet(e){
    setFabricColor(e)
    if(e.length ===0){
        setFabricColorError('error')
    }
    else{
        setFabricColorError('')

    }

}

    function popHandler(){
        if(pop === true){
            setPop(false)
        }
        else{
            setPop(true)
        }
    }
    const formatMoney = (amount) => {
      // Ensure the input is a number, and format it with commas
      return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits: 2,  // Limit to two decimal places
      }).format(amount);
    };
    
    function deleteModalHandler(){
        if(deleteModal === true){
            setdeleteModal(false)
        }
        else{
            setdeleteModal(true)
        }
    }

    async function deleteFabric(){
        setLoading(true)
        let fabricDoc = doc(fireStore,'Fabrics',fabricId)
        const userDoc = doc(fireStore, 'Andnet', 'stats');
        const currentData = await getDoc(fabricDoc);
        const statsData = await getDoc(userDoc);
       
        const currentTotalExpense = statsData.data() && statsData.data().totalExpense || 0;
        const currentTotalMoneySpentOnFabrics = statsData.data() && statsData.data().totalMoneySpentOnFabrics || 0;
    
        const totalDeletedPrice = parseFloat(currentData.data().numberOfItem) * parseFloat(currentData.data().pricePerItem) ;
        const TotalExpense = isNaN(totalDeletedPrice) ? currentTotalExpense : currentTotalExpense - totalDeletedPrice;
        const TotalMoneySpentOnFabrics = isNaN(totalDeletedPrice) ? currentTotalMoneySpentOnFabrics : currentTotalMoneySpentOnFabrics - totalDeletedPrice;
     
      await setDoc(userDoc, { totalExpense: TotalExpense,totalMoneySpentOnFabrics:TotalMoneySpentOnFabrics }, { merge: true });

      await deleteDoc(fabricDoc).then(
         history.push('/admin/fabric')
      )  
        
      

    }
    

    useEffect(() => {
        const fabricDoc = doc(fireStore, 'Fabrics', fabricId);
    
        // Subscribe to real-time updates
        const unsubscribe = onSnapshot(fabricDoc, (snapshot) => {
            if (snapshot.exists()) {
                setfabric([{ ...snapshot.data() }]); // Directly set the fetched data
            } else {
                console.error("No such document!");
            }
        });
    
        return () => unsubscribe();
    }, [fireStore, fabricId]); 

    function fabricNameEditorSet(){
      if(fabricNameEditor){
        setfabricNameEditor(false)
      }
      else{
        setfabricNameEditor(true)
        setFabricName('')
      }
    }

    function handleSavepricePerItem(fabricId){
      if(pricePerItem.length !==0){
        let fabricDoc = doc(fireStore,'Fabrics',fabricId)
      setDoc(fabricDoc,{pricePerItem},{merge:true})
      setpricePerItemEditor(false)
      }
      else{
        setpricePerItemError('error')

      }
    }
    const convertTimestampToDate = (timestamp) => {
      const date = timestamp.toDate();
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };
    
    function pricePerItemEditorSet(){
      if(pricePerItemEditor){
        setpricePerItemEditor(false)
      }
      else{
        setpricePerItemEditor(true)
        setpricePerItem('')
      }
    }

    function handleSaveFabricName(fabricId){
      if(fabricName.length !==0){
        let fabricDoc = doc(fireStore,'Fabrics',fabricId)
      setDoc(fabricDoc,{fabricName},{merge:true})
      setfabricNameEditor(false)
      }
      else{
        setFabricNameError('error')

      }
    }
    function fabricNameEditorSet(){
      if(fabricNameEditor){
        setfabricNameEditor(false)
      }
      else{
        setfabricNameEditor(true)
        setFabricName('')
      }
    }

    function handleSaveFabricName(fabricId){
      if(fabricName.length !==0){
        let fabricDoc = doc(fireStore,'Fabrics',fabricId)
      setDoc(fabricDoc,{fabricName},{merge:true})
      setfabricNameEditor(false)
      }
      else{
        setFabricNameError('error')

      }
    }

    function fabricColorEditorSet(){
      if(fabricColorEditor){
        setfabricColorEditor(false)
      }
      else{
        setfabricColorEditor(true)
        setFabricColor('')
      }
    }

    function handleSaveFabricColor(fabricId){
      
      if(fabricColor.length !==0){
        let fabricDoc = doc(fireStore,'Fabrics',fabricId)
        setDoc(fabricDoc,{fabricColor},{merge:true})
        setfabricColorEditor(false)
      }
      else{
        setfabricColorEditor('error')

      }
    }
    

  return (
    <div>
   <Nav comp='adminApear' popProp={pop}/>
   <div className='h-full w-full bg-lightPrimary dark:!bg-navy-900 px-5'>

{
    fabric.length !=0 ?
    fabric.map(i=>{
        const {meterPerKg,supplierName, supplierPhoneNumber,createdTime,note,reciept,fabricName,fabricPhoto,fabricWidth,fabricHeight,fabricWeight,stock,pricePerItem,numberOfItem,fabricColor} = i
        return(
<main className='mx-[12px] h-full flex-none transition-all md:pr-2   ' onClick={popHandler}>
<nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
  <div className="ml-[6px]">
   
    <p className="shrink text-[33px] capitalize text-navy-700 ">
  
    </p>
  </div>
  <div className="flex items-center">
   
   
    <div className="relative flex">
      <div className="flex">
        
       
      </div>
      
    </div>
 
    
    <div className="relative flex ml-2 ">
      <div className="flex items-center ">
     {
            email != yonas &&
            <svg onClick={deleteModalHandler} xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-trash mr-2 cursor-pointer hover:text-red-600" viewBox="0 0 16 16">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
</svg>}
<Link
        className="font-bold capitalize hover:text-navy-700 dark:hover:text-white css-7vq5hu"
        to={`/admin/fabric/${fabricId}`}
      >
      {fabricName}
      
      </Link>
      </div>
      <div className="py-2 top-8 -left-[180px] w-max absolute z-10 origin-top-right transition-all duration-300 ease-in-out scale-0">
        <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700  dark:shadow-none">
          <div className="p-4">
            <div className="flex items-center gap-2">
              <p className="text-sm font-bold text-navy-700 ">
                👋 Hey, Adela
              </p>{" "}
            </div>
          </div>
          <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />
          <div className="flex flex-col p-4">
            <Link
              to=''
              className="text-sm text-gray-800  hover:"
            >
              Profile Settings
            </Link>
                <Link
              to=''
              className="mt-3 text-sm text-gray-800  hover:"
            >
              Newsletter Settings
            </Link>
               <Link
              to=''
              className="mt-3 text-sm font-medium text-red-500 hover:text-red-500 transition duration-150 ease-out hover:ease-in"
            >
              Log Out
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

{
    deleteModal === true &&
    <div id="amountModal" class="">
    <div class="relative p-4 w-full h-full flex items-center justify-center deleteModal " >
        <div class="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5 w-full" >
        {
            loading === false ?
            <button onClick={deleteModalHandler} type="button" class="text-gray-900 absolute top-2 right-2 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="deleteModal">
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            <span class="sr-only">Close modal</span>
        </button>
        :
        <button  type="button" class="cursor-not-allowed text-gray-900 absolute top-2 right-2 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="deleteModal">
        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
        <span class="sr-only">Close modal</span>
    </button>
        }
            <p className='flex justify-center items-center mb-4'>
            <p class=" text-gray-900 css-7vq5hu mr-2" style={{fontSize:'20px'}}>
              
             Are you sure you want to delete {fabricName}? </p>
             <span className='items'></span>
            </p>

           
      
              {
                loading === false?
                <button onClick={deleteFabric} className="page-book button button-main mt-6"style={{background:'red',color:'white'}} >   <span>Delete </span>
             </button>
             :
             <button  className="page-book button button-main mt-6 cursor-not-allowed"style={{background:'red',color:'white',cursor:'not-allowed'}} >   <span>Deleting </span>
             </button>
              }

{
                loading === false?
                <button onClick={deleteModalHandler} className="page-book button button-main mt-3 border-2 border" style={{background:'white',color:'black'}}>   <span>Cancel </span>
             </button>
             :
             <button  className="page-book button button-main mt-3 border-2 border cursor-not-allowed" style={{background:'white',color:'black',cursor:'not-allowed'}}>   <span>Cancel </span>
             </button>
              }
            
     
         
        </div>
    </div>
  </div>
}
<div class=" -800 py-8">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex flex-col md:flex-row -mx-4">
            <div class="md:flex-1  px-4">
                <div class="h-[460px] rounded-lg bg-gray-300 -700 mb-4">
                    <img class="w-full h-full object-cover" src={fabricPhoto} alt="Fabric Image"/>
                </div>
             
            </div>
            <div class="md:flex-1 px-4">
            {
              fabricNameEditor === false ?
              <div className='flex items-center gap-2 mb-4'>
              <h2 class="text-2xl font-bold text-gray-800  ">{fabricName}</h2>

     {     
            email != yonas &&
            <svg onClick={fabricNameEditorSet} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil cursor-pointer" viewBox="0 0 16 16">
  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
</svg>}
              </div>
              :
              <div className='flex items-center gap-2 mb-4'>
             <div style={{minWidth:'50%'}}>
             <input
            type="text"
            placeholder="Fabric Name"
            autoComplete="Fabric Name"
            defaultValue={fabricName}
            onChange={e=>fabricNameSet(e.target.value)}
            className="input book-input  "  style={{borderBottom:'1px solid black'}} 
          />
          {
            fabricNameError === 'error' &&
            <div  class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
  <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
  <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
          }
             </div>
             <button className="page-book button button-main" onClick={()=>handleSaveFabricName(fabricId)}>
            <span>Save</span>
          </button>
             <p className='cursor-pointer' onClick={fabricNameEditorSet}> X</p>
              </div>
            }

                <p class="text-gray-600  text-sm mb-4">
                    {note}
                </p>
                <div class="flex mb-4">
                    <div class="mr-4">
                        <span class="font-bold text-gray-700 ">Total Price: </span>
                        <a class="text-gray-600 " target='_blank' href={reciept}>{ formatMoney(pricePerItem  * numberOfItem)} ETB</a>
                    </div>
                    <div>
                        <span class="font-bold text-gray-700 ">In Stock: </span>
                        <span class="text-gray-600 ">{formatMoney(stock)}{fabricWidth?' Meter':' Kg'}</span>
                    </div>
                  
                </div>
                {
                    meterPerKg &&
                    <div>
                    <span class="text-gray-600 ">1 Kg equals {meterPerKg} Meter</span>
                </div>
                   }
                <div class="my-4">
                    <div class="flex items-center mt-2">
                    <span class="font-bold text-gray-700 "> Number of Fabrics: </span>

                        <span class=" text-gray-600 pl-2 "> {numberOfItem}</span>
                    </div>
                </div>
                <div class="mb-4 flex items-center gap-2">
                    <div class="flex items-center mt-2">
                    <span class="font-bold text-gray-700 "> Price per Fabric: </span>

                        <span class=" text-gray-600 pl-2 "> {pricePerItem} ETB</span>
                    </div>
                </div>
                <div class="mb-4 flex items-center gap-2">
                    <div class="flex items-center mt-2">
                    <span class="font-bold text-gray-700 "> Time: </span>

                        <span class=" text-gray-600 pl-2 "> {convertTimestampToDate(createdTime)} </span>
                    </div>
                </div>
               {
                fabricColorEditor === false ?
                <div class="mb-4">
                <span class="font-bold text-gray-700 ">Fabric Color:</span>
                <div class="flex items-center mt-2 gap-2">
                    <button class="w-6 h-6 rounded-full  mr-2 border" style={{backgroundColor:fabricColor}}></button>
              
           {
            email != yonas &&
            <svg onClick={fabricColorEditorSet} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil cursor-pointer" viewBox="0 0 16 16">
            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
          </svg>
           }
                </div>
            </div>
            :
            <div class="mb-4 flex items-center">
             <div style={{minWidth:'50%'}}>

               <input
            type="color"
            placeholder="Fabric Color"
            autoComplete="Fabric Color"
            onChange={e=>fabricColorSet(e.target.value)}
              defaultValue={fabricColor}
            className="input book-input "style={{borderBottom:'1px solid black'}}
             />
              {
            fabricColorError === 'error' &&
            <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
  <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
  <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
          }
</div>

            <button className="page-book button button-main" style={{minWidth:'15%'}}onClick={()=>handleSaveFabricColor(fabricId)}>
            <span>Save</span>
          </button>
             <p className='cursor-pointer text-center' style={{minWidth:'10%'}}onClick={fabricColorEditorSet}> X</p>
        </div>
               }
                <div class="mb-4">
                    <span class="font-bold text-gray-700 "> Size:</span>
                    <div class="flex items-center mt-2">
                        <div class="bg-black -700 text-white  py-2 px-4 rounded-full font-bold mr-2  ">{fabricWidth ? (fabricWidth +'m' + ' * ' + fabricHeight+'m') : fabricWeight + 'Kg'}</div>
                    </div>
                </div>
                <div className='mb-4'>
                    <span class="font-bold text-gray-700 ">Supplier's Name:</span>
                    <p class="text-gray-600  text-sm mt-2">
   {supplierName}
                    </p>
                </div>
                <div>
                    <span class="font-bold text-gray-700 ">Supplier's Phone:</span>
                    <p class="text-gray-600  text-sm mt-2">
   {supplierPhoneNumber}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

</main>
        )
    })
:
<div className='mx-[12px] h-full flex-none transition-all md:pr-2  '>
<div className="sc-dced6a82-0 fEUgVc">
  <div className="sc-ab4f884-0 gMSCii">
    <img src="https://cdn.arc.dev/images/developer-dashboard/jobs-empty-case.svg" />
    <div width="240px" className="sc-ab4f884-1 jVlTeU">
      <p className='items mt-6'>
        You haven’t added any any fabrics
        <br />
      </p>
    </div>
  </div>
</div>

</div>
}
    </div>

    </div>
  )
}
