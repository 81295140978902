import { setDoc,doc, onSnapshot, collection, getDoc, serverTimestamp } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { auth, fireStore } from '../firebase'
import { v4 } from 'uuid'
import Aws from'aws-sdk'
import Nav from './nav'
import { onAuthStateChanged } from 'firebase/auth'
export default function Fabrics() {
    const [addNewFabric,setaddNewFabric]= useState(false)
    const [fabricName,setFabricName]= useState('')
    const [totalPrice,settotalPrice]= useState()
    const [stock,setstock]= useState()
    const [meterPerKg,setmeterPerKg]= useState('')
    const [meterPerKgError,setmeterPerKgError]= useState()
    const [email,setemail]= useState()
    let yonas = 'yonas@gmail.com'

    const [fabricNameError,setFabricNameError]= useState('')
    const [fabricType,setFabricType]= useState('')
    const [fabricTypeError,setFabricTypeError]= useState('')
    const [fabricColor,setFabricColor]= useState('')
    const [fabricColorError,setFabricColorError]= useState('')
    const [fabricWidth,setFabricWidth]= useState('')
    const [fabricWidthError,setFabricWidthError]= useState('')
    const [fabricWeight,setfabricWeight]= useState('')
    const [fabricWeightError,setfabricWeightError]= useState('')
    const [fabricHeight,setFabricHeight]= useState('')
    const [fabricHeightError,setFabricHeightError]= useState('')
    const [fabricPhoto,setFabricPhoto]= useState([])
    const [fabricPhotoError,setFabricPhotoError]= useState('')
    const [supplierName,setsupplierName]= useState('')
    const [supplierNameError,setsupplierNameError]= useState('')
    const [supplierPhoneNumber,setsupplierPhoneNumber]= useState('')
    const [supplierPhoneNumberError,setsupplierPhoneNumberError]= useState('')
    const [numberOfItem,setnumberOfItem]= useState('')
    const [numberOfItemError,setnumberOfItemError]= useState('')
    const [pricePerItem,setpricePerItem]= useState('')
    const [pricePerItemError,setpricePerItemError]= useState('')
    const [pricePerMeter,setpricePerMeter]= useState('')
    const [pricePerMeterError,setpricePerMeterError]= useState('')
    const [reciept,setreciept]= useState([])
    const [recieptError,setrecieptError]= useState('')
    const [notes,setnotes]= useState('')
    const [notesError,setnotesError]= useState('')
    const [garmentApplication,setgarmentApplication]= useState('')
    const [garmentApplicationError,setgarmentApplicationError]= useState('')
    const [garmentIntendedFor,setgarmentIntendedFor]= useState('')
    const [garmentIntendedForError,setgarmentIntendedForError]= useState('')
    const [metricSystem,setmetricSystem]= useState('')
    const [metricSystemError,setmetricSystemError]= useState('')
    const [uploadProgress,setUploadProgress] = useState()
    const [loading,setloading] = useState(true)
    const [success,setsuccess] = useState(false)
    const [error,setError] = useState()
    const [progress,setprogress] = useState()
    const [fabric,setFabric] = useState([])
    let [pop,setPop] = useState(false)
    const formatNumber = (amount) => {
      // Ensure the input is a number, and format it with commas
      return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits: 2,  // Limit to two decimal places
      }).format(amount);
    };

    function popHandler(){
      if(pop === true){
          setPop(false)
      }
      else{
          setPop(true)
      }
  }

  const formatMoney = (amount) => {
    // Ensure the input is a number, and format it with commas
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,  // Limit to two decimal places
    }).format(amount);
  };
 useEffect(()=>{
  let totalPrice = 0
  totalPrice = pricePerItem * numberOfItem
 

  settotalPrice((totalPrice))
 },[pricePerItem,numberOfItem])

 useEffect(() => {
  let stock = 0;

  // Calculate the stock based on the metric system and conversion factor
  if (metricSystem === 'Meter') {
    // Calculate stock in meters (using fabric width, height, and number of items)
    stock = numberOfItem * fabricWidth * fabricHeight;
  } else if (metricSystem === 'Kg') {
    // Calculate stock in kg first, then convert to meters
    stock = numberOfItem * fabricWeight;
    stock = stock * meterPerKg;  // Convert kg to meters using your conversion factor
  }

  // Set the calculated stock
  setstock(stock);

}, [fabricWidth, fabricHeight, fabricWeight, numberOfItem, metricSystem,meterPerKg]);


useEffect(() => {
      // Fetch the courses data from the courses collection with the courseId
    
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setemail(user.email)
        
    
         
        }
      });
    
      return () => {
        unsubscribe();

      };
    }, []);
    function fabricNameSet(e){
        setFabricName(e)
        if(!e){
            setFabricNameError('error')
        }
        else{
            setFabricNameError('')

        }

    }
    function metricSystemSet(e){
      setmetricSystem(e)
      setfabricWeight('')
      setFabricHeight('')
      setFabricWidth('')
      if(!e){
          setmetricSystemError('error')
      }
      else{
          setmetricSystemError('')

      }

  }

    function addNewFabricModal(){
      setloading(true)
      setFabricName('')
          setfabricWeight('')
          setFabricType('')
          setFabricWidth('')
          setFabricColor('')
          setnotes('')
          setsupplierName('')
          setsupplierPhoneNumber('')
          setnumberOfItem('')
          setpricePerItem('')
          setpricePerMeter('')
          setreciept('')
          setFabricPhoto('')
      setTimeout(() => {
        if(addNewFabric === true){
          setloading(false)
          setaddNewFabric(false)
          
        }
        else{
          setloading(false)
          setaddNewFabric(true)
        }
        
      }, 3000);
    }
    function fabricTypeSet(e){
        setFabricType(e)
        if(!e){
            setFabricTypeError('error')
        }
        else{
            setFabricTypeError('')

        }

    }
    function fabricColorSet(e){
        setFabricColor(e)
        if(!e){
            setFabricColorError('error')
        }
        else{
            setFabricColorError('')

        }

    }
    function fabricWidthSet(e){
        setFabricWidth(e)
        if(!e){
            setFabricWidthError('error')
        }
        else{
            setFabricWidthError('')

        }

    }
    function fabricWeightSet(e){
      setfabricWeight(e)
      if(!e){
          setfabricWeightError('error')
      }
      else{
          setfabricWeightError('')

      }

  }
  function fabricmeterPerKgSet(e){
    setmeterPerKg(e)
    if(!e){
        setmeterPerKgError('error')
    }
    else{
        setmeterPerKgError('')

    }

}
    function fabricHeightSet(e){
        setFabricHeight(e)
        if(!e){
            setFabricHeightError('error')
        }
        else{
            setFabricHeightError('')

        }

    }
    function fabricPhotoSet(file){
    
      if (file) {
        setFabricPhoto(file); 
       
        
        
        const reader = new FileReader();
       
        reader.readAsDataURL(file); 
      }
        if(!file){
            setFabricPhotoError('error')
        }
        else{
            setFabricPhotoError('')

        }

    }
    function supplierNameSet(e){
        setsupplierName(e)
        if(!e){
            setsupplierNameError('error')
        }
        else{
            setsupplierNameError('')

        }

    }
    function supplierPhoneNumberSet(e){
        setsupplierPhoneNumber(e)
        if(!e){
            setsupplierPhoneNumberError('error')
        }
        else{
            setsupplierPhoneNumberError('')

        }

    }

    function numberOfItemsSet(e){
        setnumberOfItem(e)
        if(e <= 0){
            setnumberOfItemError('error')
        }
        else{
            setnumberOfItemError('')

        }

    }
    function notesSet(e){
        setnotes(e)
      

    }
    Aws.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, 
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, 
      region: 'us-east-1', // Your S3 bucket region
    })
  
    function pricePerItemSet(e){
        setpricePerItem(e)
        if(e <= 0){
            setpricePerItemError('error')
        }
        else{
            setpricePerItemError('')

        }

    }
    function pricePerMeterSet(e){
      setpricePerMeter(e)
      if(e <= 0){
          setpricePerMeterError('error')
      }
      else{
          setpricePerMeterError('')

      }

  }
    function recieptSet(file){
         if (file) {
        setreciept(file); 
       
        
        
        const reader = new FileReader();
       
        reader.readAsDataURL(file); 
      }
        if(!file){
            setrecieptError('error')
        }
        else{
            setrecieptError('')

        }

    }
    function garmentApplicationSet(e){
        setgarmentApplication(e)
        if(!e){
            setgarmentApplicationError('error')
        }
        else{
            setgarmentApplicationError('')

        }

    }
    function garmentIntendedForSet(e){
      setgarmentIntendedFor(e)
      if(!e){
          setgarmentIntendedForError('error')
      }
      else{
          setgarmentIntendedForError('')

      }

  }


  
   async function addFabric() {
    const userDoc = doc(fireStore, 'Andnet', 'stats');
    const statsData = await getDoc(userDoc);
    const currentTotalExpense = statsData.data().totalExpense || 0;
    const currentTotalMoneySpentOnFabrics = statsData.data().totalMoneySpentOnFabrics || 0;

    const totalPriceSpent = parseFloat(totalPrice);
    const TotalExpense = isNaN(totalPriceSpent) ? currentTotalExpense : currentTotalExpense + totalPriceSpent;
    const TotalMoneySpentOnFabrics = isNaN(totalPriceSpent) ? currentTotalMoneySpentOnFabrics : currentTotalMoneySpentOnFabrics + totalPriceSpent;
    
    let data = {
      fabricName: fabricName,
      fabricColor: fabricColor,
      fabricWidth: fabricWidth && fabricWidth,
      fabricHeight: fabricHeight && fabricHeight,
      fabricWeight:fabricWeight && fabricWeight,
      fabricPhoto: fabricPhoto, 
      notes: notes,
      stock: stock,
      metricSystem:metricSystem,
      supplierName: supplierName,
      supplierPhoneNumber: supplierPhoneNumber,
      numberOfItem: numberOfItem,
      pricePerItem: pricePerItem,
      pricePerMeter: pricePerMeter,
      meterPerKg:meterPerKg && meterPerKg,
      createdTime:serverTimestamp(),
      reciept: reciept,  
  };

    // Validate inputs
    if (!fabricName) {
        setFabricNameError('error');
        return;
    } else {
        setFabricNameError('');
    }

 

    if (!fabricType) {
        setFabricTypeError('error');
        return;
    } else {
        setFabricTypeError('');
    }

    if (!fabricColor) {
        setFabricColorError('error');
        return;
    } else {
        setFabricColorError('');
    }

   if(!metricSystem){
    setmetricSystemError('error')
   }
   else{
    setmetricSystemError('')
    
   }

   if(!(fabricWidth? fabricWidth :fabricWeight && fabricWeight)){
    metricSystem === 'Meter'? setFabricWidthError('error'): setfabricWeightError('error')

   }
   else{
    fabricWidth? setFabricWidthError(''): setfabricWeightError('')

   }

   if((metricSystem === 'Kg' && !meterPerKg)){
    setmeterPerKgError('error')

   }
   else{
    setmeterPerKgError('')

   }

    if (!fabricPhoto) {
        setFabricPhotoError('error');
        return;
    } else {
        setFabricPhotoError('');
    }

    if (!supplierName) {
        setsupplierNameError('error');
        return;
    } else {
        setsupplierNameError('');
    }

    if (!supplierPhoneNumber) {
        setsupplierPhoneNumberError('error');
        return;
    } else {
        setsupplierPhoneNumberError('');
    }

    if (numberOfItem <= 0) {
        setnumberOfItemError('error');
        return;
    } else {
        setnumberOfItemError('');
    }

    if (pricePerItem  <= 0) {
        setpricePerItemError('error');
        return;
    } else {
        setpricePerItemError('');
    }
    if (pricePerMeter  <= 0) {
      setpricePerMeterError('error');
      return;
  } else {
      setpricePerMeterError('');
  }
    if (!reciept) {
        setrecieptError('error');
        return;
    } else {
        setrecieptError('');
    }

    try {
        // Initialize loading and progress
        setloading(true);
        setprogress(0);

        const s3 = new Aws.S3();
        const uploadedFabricImageUrls = []; // To store the URLs of fabric photos
        const uploadedReceiptImageUrls = []; // To store the URLs of receipt photos

        // Function to upload a single file
        const uploadFile = async (file, folderName, imageUrlsArray) => {
            if (!file) {
                console.log(`No file provided for ${folderName}.`);
                return;
            }

            const params = {
                Bucket: 'andnet', // Replace with your bucket name
                Key: `${folderName}/${file.name}`, // Path in the bucket
                Body: file, // The file object
                ContentType: file.type, // Content type based on the file's mime type
            };

            // Start uploading the file
            const upload = s3.upload(params);

            // Track upload progress for this file
            upload.on('httpUploadProgress', (progress) => {
                const progressPercentage = Math.round((progress.loaded / progress.total) * 100);
               
                setUploadProgress(progressPercentage); // Update global progress or handle separately
            });

            // Perform the upload and wait for completion
            await new Promise((resolve, reject) => {
                upload.send((err, data) => {
                    if (err) {
                        console.log(`Error uploading file ${file.name}:`, err);
                        alert(`Error uploading ${file.name}`);
                        reject(err);
                    } else {
                        console.log(`File ${file.name} uploaded successfully:`, data);
                        imageUrlsArray.push(data.Location); // Store the uploaded file URL
                        resolve();
                    }
                });
            });
        };

        // Upload fabric photo and receipt photo
        await uploadFile(fabricPhoto, 'fabric', uploadedFabricImageUrls);
        await uploadFile(reciept, 'receipt', uploadedReceiptImageUrls);

        // After successful upload, add fabric data to Firestore
        data.fabricPhoto = uploadedFabricImageUrls[0]; // Store the URL of the fabric photo
        data.reciept = uploadedReceiptImageUrls[0]; // Store the URL of the receipt photo

        await setDoc(doc(fireStore, 'Fabrics', fabricName[0] + fabricType[0] + fabricName[fabricName.length - 1] + fabricType[fabricType.length - 1] + v4().slice(0, 5)), data);
        await setDoc(userDoc, { totalExpense: TotalExpense,totalMoneySpentOnFabrics:TotalMoneySpentOnFabrics }, { merge: true });
        setloading(false);
        
        

    } catch (error) {
        console.error('An error occurred while adding the fabric:', error);
        setloading(false);
        setError((prevErrors) => ({
            ...prevErrors,
            general: 'An error occurred while adding the fabric. Please try again.',
        }));
    }
}



const timestampToString = (timestamp) => {
if(timestamp){
  const date = timestamp.toDate();
  const currentDate = new Date();
  const timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);

  const secondsInMinute = 60;
  const secondsInHour = 3600;
  const secondsInDay = 86400;
  const secondsInWeek = 604800;
  const secondsInMonth = 2592000;
  const secondsInYear = 31536000;

  let relativeTime = '';

  if (timeDifferenceInSeconds < secondsInMinute) {
    relativeTime = `${timeDifferenceInSeconds}s ago`;
  } else if (timeDifferenceInSeconds < secondsInHour) {
    const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
    relativeTime = `${minutes}m ago`;
  } else if (timeDifferenceInSeconds < secondsInDay) {
    const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
    relativeTime = `${hours}h ago`;
  } else if (timeDifferenceInSeconds < secondsInWeek) {
    const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
    relativeTime = `${days}d ago`;
  } else if (timeDifferenceInSeconds < secondsInMonth) {
    const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
    relativeTime = `${weeks}w ago`;
  } else if (timeDifferenceInSeconds < secondsInYear) {
    const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
    relativeTime = `${months}mo ago`;
  } else {
    const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
    relativeTime = `${years}y ago`;
  }

  return relativeTime;
}

};

useEffect(() => {
  const unsubscribe = onSnapshot(
      collection(fireStore, 'Fabrics'),
      (docs) => {
          let temp = [];
          docs.forEach(snapshot => {
              // Push data into the temporary array with fabricId
              temp.push({ ...snapshot.data(), fabricId: snapshot.id});
          });
          setFabric(temp); // Update the state with the fetched data
          setloading(false);  // Set loading to false when data is fetched
      },
      (err) => {
          setError(err.message);  // Handle any error while fetching
          setloading(false);
      }
  );

  // Cleanup on unmount
  return () => unsubscribe();
}, []);

  function removeSuccess(){
    setsuccess(false)
  }

  return (
    <div>
   <div c>
   <Nav compType='3' popProp={pop}/>
<div className='h-full w-full bg-lightPrimary dark:!bg-navy-900 px-3 '>

<main className='mx-[12px] h-full flex-none transition-all md:pr-2   mt-5' onClick={popHandler}>
<nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 ">
  <div className="ml-[6px]">
   
    <p className="shrink text-[33px] capitalize text-navy-700">
     
    </p>
  </div>
  <div className="flex items-center">
   
   
    <div className="relative flex">
      <div className="flex">
       
    
      </div>
      
    </div>
 
    
    <div className="relative flex ml-2 ">
      <div className="flex">
     
      <Link
        className="font-bold capitalize hover:text-navy-700 dark:hover:text-white css-7vq5hu"
        to="/admin/fabric"
      >
       Fabric List  {uploadProgress && uploadProgress + '%'}

      </Link>
      </div>
     
    </div>
  </div>
</nav>

{
   loading === true ?
   <div className='relative flex justify-center items-center h-[90vh]'>
   <div className="typewriter">
     <div className="slide">
       <i />
     </div>
     <div className="paper" />
     <div className="keyboard" />
   </div>
   </div>
   :
   success == true ?
<div id='successAddFabric' className='flex items-center justify-center'>


<div class="relative p-4 w-full  h-full md:h-auto"style={{width:"45%"}}>
 <div class="relative p-4 text-center bg-white rounded-lg shadow  sm:p-5" >
   
     <button onClick={removeSuccess}  type="button" class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
         <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
         <span class="sr-only">Close modal</span>
     </button>


     <div class="w-12 h-12 rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
         <svg aria-hidden="true" class="w-8 h-8 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
         <span class="sr-only">Success</span>
     </div>
     <p class="mb-4 text-lg font-semibold text-gray-900  capitalize">Successfully Added {fabricName}</p>
<button onClick={removeSuccess}  type="button" class="text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 Mooli mb-12 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none dark:focus:ring-blue-800">Continue</button>


 </div>
</div>



</div>
   :
    (fabric.length === 0  || addNewFabric === true) && email != yonas ?
    <div className="fabricDetails page-form  mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
{
  fabric.length !==0 && email != yonas &&
  <button className="page-book button button-main absolute left-10 top-24" style={{backgroundColor:'red',width:'auto',zIndex:1000}} onClick={addNewFabricModal}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
</svg>
              </button>
}
        <div className="page-inputs">
               <p className="header" style={{fontSize:'25px'}}>

              Fabric Details 
            </p> 

               <div class="relative z-0 w-full  group">

         <input
           type="text"
           name='loating_name'
           onChange={e=>fabricNameSet(e.target.value)}
           placeholder=''
           style={{borderBottom:'1px solid black',borderRadius:'0'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
         />
       <label for="floating_name" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Fabric Name </label>
       </div>    
        
          {
            fabricNameError === 'error' &&
            <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
  <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
  <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
          }
               <div class="relative z-0 w-full mb-3 group">

         <input
           type="text"
           name='loating_name'
           onChange={e=>fabricTypeSet(e.target.value)}
           placeholder=''
           style={{borderBottom:'1px solid black',borderRadius:'0'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
         />
       <label for="floating_name" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Fabric Type</label>
       </div>  
   
         
              {
            fabricTypeError === 'error' &&
            <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
  <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
  <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
          }
          <input
            type="color"
            placeholder="Fabric Color"
            autoComplete="Fabric Color"
            onChange={e=>fabricColorSet(e.target.value)}

            className="input book-input "style={{borderBottom:'1px solid black'}}
             />
              {
            fabricColorError === 'error' &&
            <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
  <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
  <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
          }
             <select name="Weighing System" id="" className='input book-input '
            onChange={e=>metricSystemSet(e.target.value)}
            >
          <option value="" className='input book-input ' >Weighing System </option>

            <option value="Meter" className='input book-input '>Meter</option>
            <option value="Kg" className='input book-input '>Kg</option>
            



          </select>
          {
            metricSystemError === 'error' &&
            <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
  <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
  <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
          }
         {
          metricSystem === 'Meter'?
          <div className="w-full page-inputs">
               <div class="relative z-0 w-full  group">

<input
  type="number"
  onChange={e=>fabricWidthSet(e.target.value)}
  placeholder=''
  value={fabricWidth}
  style={{borderBottom:'1px solid black',borderRadius:'0'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
/>
<label  class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Fabric Width </label>
</div>    
                {
            fabricWidthError === 'error' &&
            <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
  <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
  <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
          }
        
         
        <div class="relative z-0 w-full  group">

<input
  type="number"
  
  onChange={e=>fabricHeightSet(e.target.value)}
  value={fabricHeight}
  placeholder=''
  style={{borderBottom:'1px solid black',borderRadius:'0'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
/>
<label  class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Fabric Height </label>
</div> 
            {
          fabricHeightError === 'error' &&
          <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
<strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
<span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
        }
       
        
          </div>
          : metricSystem === 'Kg' &&
          <div className="w-full page-inputs">
        
         
       
                   <div class="relative z-0 w-full  group ">

<input
  type="number"
  name='loating_name'
  value={fabricWeight}
  onChange={e=>fabricWeightSet(e.target.value)}
  placeholder=''
  style={{borderBottom:'1px solid black',borderRadius:'0'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
/>
<label for="floating_name" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Fabric Weight (Kg) </label>
</div> 
              {
            fabricWeightError === 'error' &&
            <div  class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
  <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
  <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
          }
                
<div class="relative z-0 w-full  group ">
<input
  type="number"
  name='floating_name'
  onChange={e=>fabricmeterPerKgSet(e.target.value)}
  placeholder=''
  style={{borderBottom:'1px solid black',borderRadius:'0'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
/>
<label for="floating_name" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Meter per Kg </label>
</div> 
              {
            meterPerKgError === 'error' &&
            <div  class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
  <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
  <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
          }

          </div>
         }
   

         <div className="file-upload-container flex">
  <input
    id="Fabric-photo"
    type="file"
    onChange={e =>fabricPhotoSet(e.target.files[0])}
    className="file-input"
  />
  <div className="custom-text">
    Fabric Photo
  </div>
<p style={{fontSize:'13px',marginLeft:'5px'}}>{fabricPhoto && fabricPhoto.name}</p>

</div>
              {
            fabricPhotoError === 'error' &&
            <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
  <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
  <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
          }
              <div class="relative z-0 w-full mb-5 group">

<input
  type="text"
  name='loating_name'
  onChange={e=>notesSet(e.target.value)}
  placeholder=''
  style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
/>
<label for="floating_name" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Additional Notes (Optional) </label>
</div>
             

       <p className="header" style={{fontSize:'25px'}}>

Supplier's Details
            </p>  
      
            <div class="relative z-0 w-full mb-3 group">

<input
  type="text"
  name='loating_name'
  onChange={e=>supplierNameSet(e.target.value)}
  placeholder=''
  style={{borderBottom:'1px solid black',borderRadius:'0'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
/>
<label for="floating_name" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Supplier's Name</label>
</div>
        
            {
           supplierNameError === 'error' &&
           <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
 <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
 <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
         }

<div class="relative z-0 w-full  group">

<input
  type="tel"
  name='loating_name'
  onChange={e=>supplierPhoneNumberSet(e.target.value)}
  placeholder=''
  style={{borderBottom:'1px solid black',borderRadius:'0'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
/>
<label for="floating_name" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Supplier's Phone Number</label>
</div>
            
            {
           supplierPhoneNumberError === 'error' &&
           <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
 <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
 <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
         }

       <p className="header" style={{fontSize:'25px'}}>

Purchase Details 
            </p>  
            
            {
              totalPrice != 0 &&
            <p className="items">

              Total Cost => { formatMoney(pricePerItem) +  ' x '+ numberOfItem + ' = '+ formatMoney(totalPrice)} ETB
            </p>
            }
              {
              ((stock)) &&
            <p className="items">

              Total Stock => {formatNumber(stock) + ' Meter'}
            </p>
            }
           
           <div class="relative z-0 w-full mb-3 group">

<input
  type="number"
  name='loating_name'
  onChange={e=>numberOfItemsSet(e.target.value)}
  placeholder=''
  min='1'

  style={{borderBottom:'1px solid black',borderRadius:'0'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
/>
<label for="floating_name" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Number of pieces bought</label>
</div>
             {
            numberOfItemError === 'error' &&
            <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
  <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
  <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
          }
          <div class="relative z-0 w-full mb-3 group">

<input
  type="number"
  name='loating_name'
  onChange={e=>pricePerItemSet(e.target.value)}
  placeholder=''
  min='1'
  style={{borderBottom:'1px solid black',borderRadius:'0'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
/>
<label for="floating_name" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Purchase Price(per item)</label>
</div>
             {
            pricePerItemError === 'error' &&
            <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
  <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
  <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
          }
          <div class="relative z-0 w-full mb-3 group">

<input
  type="number"
  name='loating_name'
  onChange={e=>pricePerMeterSet(e.target.value)}
  placeholder=''
  min='1'
  style={{borderBottom:'1px solid black',borderRadius:'0'}}class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-gray-600 peer"
/>
<label for="floating_name" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Price per Meter</label>
</div>
             {
            pricePerMeterError === 'error' &&
            <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
  <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
  <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
          }
              
<div className="file-upload-container flex">
  <input
    id="Reciept-photo"
    type="file"
    onChange={e =>recieptSet(e.target.files[0])}
    className="file-input"
  />
  <div className="custom-text">
    Reciept Photo
  </div>
<p style={{fontSize:'13px',marginLeft:'5px'}}>{reciept && reciept.name}</p>

</div>
             {
            recieptError === 'error' &&
            <div style={{marginTop:'-25px'}} class="flex bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
  <strong class="font-bold pr-1 raleway" style={{fontSize:'12px'}}>Error!</strong>
  <span class="block sm:inline raleway"  style={{fontSize:'12px'}}>Field can't be empty</span>

</div>
          }
          <div className="page-lower">
        
            <div className="page-button mb-10">
              <button className="page-book button button-main" onClick={addFabric}>
                <span>Add Fabric</span>
              </button>
              
            </div>
          </div>
        </div>
      </div>
      :
     fabric.length !=0 ?
      <div className="mt-4   mx-auto mb-auto h-full min-h-[84vh] p-2 tablePadding " style={{overflowX:'scroll'}}>
{email != yonas &&
<button className="page-book button button-main absolute left-10 top-24" style={{width:'auto',zIndex:1000}} onClick={addNewFabricModal}>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
</svg>
              </button>}
              
              <div className="w">
<table class="w-full text-sm text-leftt text-gray-500 overflow-scroll">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
            <tr>
            <th scope="col" class="tablePadding text-center py-3">
                </th>
                <th scope="col" class="tablePadding text-center py-3">
                    Name
                </th>
                <th scope="col" class="tablePadding text-center py-3">
                    Stock
                </th>
                <th scope="col" class="tablePadding text-center py-3">
                    Price
                </th>
                <th scope="col" class="tablePadding text-center py-3">
                    Time
                </th>
                <th scope="col" class="tablePadding text-center py-3">
                    Action
                </th>
            </tr>
        </thead>
     {fabric && fabric.sort((a,b)=>b.createdTime-a.createdTime).map((i,index)=>{
      const{createdTime,fabricName,fabricPrice,fabricWeight,stock,fabricWidth,fabricHeight,fabricPhoto,garmentApplication,fabricId,numberOfItem, pricePerItem} = i
      return(
        <tbody>
        <tr class="border-b ">
           <th scope="row" class="tablePadding text-center py-4 font-medium text-gray-900 whitespace-nowrap">
                {index + 1}
            </th>
            <th scope="row" class="tablePadding text-center py-4 font-medium text-gray-900 whitespace-nowrap">
                {fabricName}
            </th>
            <td class="tablePadding text-center py-4">
            {formatNumber(stock)}{fabricWidth?' Meter':' Kg'}
            </td>
            <td class="tablePadding text-center py-4">
            { formatNumber(pricePerItem * numberOfItem)} ETB
            </td>
            <td class="tablePadding text-center py-4">
          {timestampToString(createdTime)}
            </td>
            <td class="tablePadding text-center py-4">
                <Link to={`/admin/fabric/${fabricId}`}>
                                <a  class="font-medium text-blue-600  hover:underline">{email != yonas?'Edit':'View'}</a></Link>
                
            </td>
        </tr>
        </tbody>





      )
     })}
</table>

</div>

     </div>
    :
    <div className='mx-[12px] h-full flex-none transition-all md:pr-2  '>
    <div className="sc-dced6a82-0 fEUgVc">
      <div className="sc-ab4f884-0 gMSCii">
        <img src="https://cdn.arc.dev/images/developer-dashboard/jobs-empty-case.svg" />
        <div width="240px" className="sc-ab4f884-1 jVlTeU">
          <p className='items mt-6'>
            You haven’t added any any fabrics
            <br />
          </p>
        </div>
      </div>
    </div>
    
    </div>


}



</main>
</div>



    </div>

    </div>
  )
}
