import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { auth,fireStore } from '../firebase'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { doc,onSnapshot } from 'firebase/firestore'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'


export default function Nav({navigationPop,compType}) {
    let[screenSize,setScreenSize] = useState(window.screen.width)
    let[user,setUser] = useState([])
    let[uid,setUid] = useState()
    let[loading,setloading] = useState(true)
    const [pop,setpop] = useState(navigationPop)
    let history = useHistory()

    useEffect(() => {
      // Fetch the courses data from the courses collection with the courseId
    
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;
          
        
    
          // Fetching user details from Firestore
          const userRef = doc(fireStore, "Andnet", uid);
          onSnapshot(userRef, (snapshot) => {
            let userDetails = [];
    
            userDetails.push({ ...snapshot.data(), id: snapshot.id });
            setUser(userDetails);  
            setloading(false)
          });
        }
      });
    
      return () => {
        unsubscribe();
        setloading(false)

      };
    }, []);

    function handleLogOut(){
      signOut(auth).then(
        history.push('/')
       )
    }

    function popHandler(){
      if(pop === true){
        setpop(false)
      }
      else{
        setpop(true)
      }
    } 
    useEffect(() => {
      const handleResize = () => {
        setScreenSize(window.screen.width);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    return   loading === true & user ?  <div className='fixed top-0 left-0 w-full bg-white flex justify-center items-center h-[100vh] z-[1000]'>
    <div className="typewriter">
      <div className="slide">
        <i />
      </div>
      <div className="paper" />
      <div className="keyboard" />
    </div>
    </div> : user.length ===0 ? (
      <div>
    
    {pop === true && (
          <div
            className="smallDevices"
            style={{
              left: '0px',
              backgroundColor: 'white',
              overflow: 'hidden',
              width: '95%',
              height: '100%',
              top: '0',
              zIndex: '100000',
              position: 'fixed',
            }}
          >
            <ul
              className="w-1/2 bg-white px-5 rounded-b-xl h-full relative"
              style={{ width: '100%', background: '#000000ff' }}
            >
           
              <div className="flex justify-between w-full mt-3 mb-10 items-center">
                <div>
                  <Link to="/">
                    <img
                      alt="image"
                      src="https://andnet.s3.us-east-1.amazonaws.com/kasterina__13_-removebg-preview+(3).png"
                      className="homeLogo object-cover"
                    />
                  </Link>
                </div>
                <button
                 onClick={popHandler}
                  id="toggleSidebar"
                  aria-expanded="true"
                  aria-controls="sidebar"
                  className="flex p-2 mr-3 text-gray-600 rounded cursor-pointer  hover:text-gray-900 "
                >
                  <svg
                    className="w-8 h-8 text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 12"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 1h14M1 6h14M1 11h7"
                    />
                  </svg>
                </button>
              </div>
            
                       
              <Link to='/'>
                    <button
                     className={`${compType === '1'&&'  border-b-2 border-[#ffff] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-white text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                    >
                     Home
                    </button>
                  </Link>
                  
                  <Link to={'/about' }>
                    <button
                     className={`${compType === '2'&&'  border-b-2 border-[#ffff] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-white text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                     
                    >
                   About Us
                    </button>
                  </Link>
                  <a
                    href='/#collection'
                    onClick={()=>setpop(false)}
                     className={`${compType === '3'&&'  border-b-2 border-[#ffff] cursor-pointer'} flex items-center text-left inline  gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-white text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                     
                    >
                      Collection
                    </a>
                  <Link to='/contact'>
                    <button
                     className={`${compType === '8'&&'  border-b-2 border-[#ffff] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-white text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                     
                    >
                     Contact Us
                    </button>
                  </Link>
                 
               
                
             
            
             
            </ul>
          </div>
        )}

               
          <div className="fixed size-0 opacity-0 inset-0 z-50 bg-black transition-opacity duration-300 ease-in-out" />
           
      
           <nav
             aria-label="Main"
             data-orientation="horizontal"
             dir="ltr"
             className="sticky left-4 top-4 z-50 mt-4 mx-auto flex w-[calc(100vw_-_32px)] items-center rounded-lg border border-black/20  p-3 max-legacy-md:hidden legacy-md:justify-between justify-between"
           >
              <div className="hideMobile absolute top-0 left-1/2 transform -translate-x-1/2 w-full flex  justify-center items-center h-full navDet">   
            <div className='z-[1001] flex justify-center w-full'>
  
              
              <Link to='/'>
                    <button
                    style={{borderColor:'black'}}
                     className={`${compType === '1'&&'  border-b-2 border-[#000000] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-black text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                    >
                     Admin
                    </button>
                  </Link>
                  
                  <Link to={'/about' }>
                    <button
                    style={{borderColor:'black'}}
                     className={`${compType === '2'&&'  border-b-2 border-[#000000] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-black text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                     
                    >
                    About Us
                    </button>
                  </Link>
  
                    <a
                    href='/#collection'
                    style={{borderColor:'black'}}
                     className={`${compType === '3'&&'  border-b-2 border-[#000000] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-black text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                     
                    >
                      Collection
                    </a>
                  <Link to={'/contact' }>

                    <button
                    style={{borderColor:'black'}}
                     className={`${compType === '4'&&'  border-b-2 border-[#000000] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-black text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                     
                    >
                     Contact
                    </button>
                  </Link>
                 
                 
            </div>
            </div>
             <Link to='/admin' className='mainNav' >
             <img className="homeLogo object-cover z-100" src='https://andnet.s3.us-east-1.amazonaws.com/kasterina+(11).png'/>
             </Link>
             <div className="flex w-full items-center justify-between  ">
         <div></div>
               <div className='flex' style={{ position: "relative" }}>
             
                 <ul
                   data-orientation="horizontal"
                   className="flex gap-2  items-center"
                   dir="ltr"
                 >
          
                  <Link to='/login'>
<button style={{borderColor:'black'}} className="button-secondary  button-secondary-mobile button bg-transparent">
    Log In
  </button></Link>
                  
                 </ul>
                 
               </div>
               
             </div>
           
             <button onClick={popHandler} data-collapse-toggle="mega-menu" type="button" className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 md:hidden" aria-controls="mega-menu" aria-expanded="false">
       <span className="sr-only">Open main menu</span>
       <svg aria-hidden="true" className="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
           <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
       </svg>
   </button>
   
           </nav>
   
  
      
      </div>
  

    ) : (
      loading === true ?  <div className='fixed top-0 left-0 w-full bg-white flex justify-center items-center h-[100vh] z-[1000]'>
    <div className="typewriter">
      <div className="slide">
        <i />
      </div>
      <div className="paper" />
      <div className="keyboard" />
    </div>
    </div> :user && user.map(i=>{
        let {photo, fullName,accountType} = i
        
        return(
          <div>
            {pop === true && (
          <div
            className="smallDevices"
            style={{
              left: '0px',
              backgroundColor: 'white',
              overflow: 'hidden',
              width: '95%',
              height: '100%',
              top: '0',
              zIndex: '100000',
              position: 'fixed',
            }}
          >
            <ul
              className="w-1/2 bg-white px-5 rounded-b-xl h-full relative"
              style={{ width: '100%', background: '#000000ff' }}
            >
           
              <div className="flex justify-between w-full mt-3 mb-10 items-center">
                <div>
                  <Link to="/admin">
                    <img
                      alt="image"
                      src="https://andnet.s3.us-east-1.amazonaws.com/kasterina__13_-removebg-preview+(3).png"
                      className="homeLogo object-cover"
                    />
                  </Link>
                </div>
                <button
                 onClick={popHandler}
                  id="toggleSidebar"
                  aria-expanded="true"
                  aria-controls="sidebar"
                  className="flex p-2 mr-3 text-gray-600 rounded cursor-pointer  hover:text-gray-900 "
                >
                  <svg
                    className="w-8 h-8 text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 12"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 1h14M1 6h14M1 11h7"
                    />
                  </svg>
                </button>
              </div>
            
                       
              <Link to='/admin'>
                    <button
                     className={`${compType === '1'&&'  border-b-2 border-[#ffff] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-white text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                    >
                     Admin
                    </button>
                  </Link>
                  
                  <Link to={'/admin/accessories' }>
                    <button
                     className={`${compType === '2'&&'  border-b-2 border-[#ffff] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-white text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                     
                    >
                   Accessories
                    </button>
                  </Link>
                  <Link to={'/admin/fabric' }>
  
                    <button
                     className={`${compType === '3'&&'  border-b-2 border-[#ffff] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-white text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                     
                    >
                      Fabrics
                    </button>
                  </Link>
                  <Link to='/admin/finishing'>
                    <button
                     className={`${compType === '8'&&'  border-b-2 border-[#ffff] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-white text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                     
                    >
                     Finishing Products
                    </button>
                  </Link>
                  <Link to='/admin/garment'>
                    <button
                     className={`${compType === '4'&&'  border-b-2 border-[#ffff] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-white text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                     
                    >
                     Garments
                    </button>
                  </Link>
                  <Link to='/admin/messages'>
                    <button
                     className={`${compType === '9'&&'  border-b-2 border-[#ffff] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-white text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                     
                    >
                     Messages
                    </button>
                  </Link>
                  <Link to='/admin/packaging'>
                    <button
                     className={`${compType === '9'&&'  border-b-2 border-[#ffff] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-white text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                     
                    >
                     Packaging
                    </button>
                  </Link>
             
            
              <li>
              <button
              onClick={handleLogOut}
                     className={`${compType === '4'&&'  border-b-2 border-[#ffff] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-white text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                     
                    >
                     Log out
                    </button>
              </li>
            </ul>
          </div>
        )}
          <div className="fixed size-0 opacity-0 inset-0 z-50 bg-black transition-opacity duration-300 ease-in-out" />
           
      
           <nav
             aria-label="Main"
             data-orientation="horizontal"
             dir="ltr"
             className=" sticky left-4 top-4 z-50 mt-4 mx-auto flex w-[calc(100vw_-_32px)] items-center rounded-lg border border-black/20 bg-white p-3 max-legacy-md:hidden legacy-md:justify-between justify-between"
           >
              <div className=" hideMobile absolute top-0 left-1/2 transform -translate-x-1/2 w-full flex  justify-center items-center h-full navDet">   
            <div className='z-[1001] flex justify-center w-full'>
  
              
              <Link to='/admin'>
                    <button
                    style={{borderColor:'black'}}
                     className={`${compType === '1'&&'  border-b-2 border-[#000000] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-black text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                    >
                     Admin
                    </button>
                  </Link>
                  
                  <Link to={'/admin/accessories' }>
                    <button
                    style={{borderColor:'black'}}
                     className={`${compType === '2'&&'  border-b-2 border-[#000000] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-black text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                     
                    >
                    Accessories
                    </button>
                  </Link>
                  <Link to={'/admin/fabric' }>
  
                    <button
                    style={{borderColor:'black'}}
                     className={`${compType === '3'&&'  border-b-2 border-[#000000] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-black text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                     
                    >
                      Fabrics
                    </button>

                  </Link>
                  <Link to={'/admin/finishing' }>
                    <button
                    style={{borderColor:'black'}}
                     className={`${compType === '8'&&'  border-b-2 border-[#000000] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-black text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                     
                    >
                    Finishing Products
                    </button>
                  </Link>

                  <Link to={'/admin/garment' }>

                    <button
                    style={{borderColor:'black'}}
                     className={`${compType === '4'&&'  border-b-2 border-[#000000] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-black text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                     
                    >
                     Garments
                    </button>
                  </Link>
                  <Link to={'/admin/messages' }>
  
                    <button
                     className={`${compType === '5'&&'  border-b-2 border-[#000000] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-black text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                     
                    >
                      Messages
                    </button>
                  </Link>
                  <Link to={'/admin/packaging' }>
                    <button
                    style={{borderColor:'black'}}
                     className={`${compType === '9'&&'  border-b-2 border-[#000000] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-black text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                     
                    >
                    Packaging
                    </button>
                  </Link>
                  <button
                  onClick={handleLogOut}
                     className={`${compType === '6'&&'  border-b-2 border-[#000000] cursor-pointer'} flex items-center justify-center gap-2  transition duration-150 ease-in-out disabled:pointer-events-none hover:underline text-b40 px-2 py-[7px] text-black text-b20 disabled:text-neutral-20 whitespace-nowrap px-4 font-medium hover:bg-blue-10 hover:no-underline focus-visible:bg-blue-10 focus-visible:outline-none aria-expanded:bg-blue-10`}
                     
                    >
                      Log out
                    </button>
            </div>
            </div>
             <Link to='/admin' className='mainNav' >
             <img className="homeLogo object-cover z-100" src='https://andnet.s3.us-east-1.amazonaws.com/kasterina+(11).png'/>
             </Link>
             <div className="flex w-full items-center justify-between  ">
         <div></div>
               <div className='flex' style={{ position: "relative" }}>
             
                 <ul
                   data-orientation="horizontal"
                   className="flex gap-2  items-center"
                   dir="ltr"
                 >
          
                  <div className=" rounded-full  overflow-hidden" style={{width:"40px",height:"40px"}}>
   
            </div>
                  
                 </ul>
                 
               </div>
               
             </div>
           
             <button onClick={popHandler} data-collapse-toggle="mega-menu" type="button" className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 md:hidden" aria-controls="mega-menu" aria-expanded="false">
       <span className="sr-only">Open main menu</span>
       <svg aria-hidden="true" className="w-8 h-8  " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
           <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
       </svg>
   </button>
   
           </nav>
   
  
      
      </div>
  
        )
      })
       );
}